import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import GridSet from "../Medias/Gallery_0/GridSet.js";
import { FaSearch, FaImages } from 'react-icons/fa';
import { Button, Col, Card } from "react-bootstrap";


const RecipeSelector = () => {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const getSuggestions = (inputValue) => {
        return GridSet.filter(recipe =>
            recipe.title.toLowerCase().includes(inputValue.toLowerCase()) ||
            recipe.country.toLowerCase().includes(inputValue.toLowerCase()) ||
            recipe.ingredient_liste.some(ingredient =>
                ingredient.toLowerCase().includes(inputValue.toLowerCase())
            ) ||
            recipe.cooking_methods.best.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const onChange = (_, { newValue }) => {
        setValue(newValue.trim());
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const selectedRecipe = GridSet.find(recipe => recipe.title.toLowerCase() === value.toLowerCase());
        if (selectedRecipe) {
            window.location.href = selectedRecipe.detailURL;
        }
    };

    const highlightMatch = (text, query) => {
        const parts = text.split(new RegExp(`(${query})`, 'gi'));
        return (
            <span>
                {parts.map((part, index) => (
                    part.toLowerCase() === query.toLowerCase() ?
                        <mark style={{ background: "#3CBE92" }} key={index}>{part}</mark> :
                        <span key={index}>{part}</span>
                ))}
            </span>
        );
    };

    const renderSuggestion = (suggestion) => (
        <Col md={4} className="mb-3"> {/* Ajuster le nombre de colonnes ici */}
            <Card as="a" style={{cursor:"pointer", textDecoration:"none"}}>
                <Card.Body>
                    <Card.Title className="text-dark">
                        {highlightMatch(suggestion.title, value)}
                    </Card.Title>
                    <Card.Text>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: '10px' }}>{highlightMatch(suggestion.country, value)}</div>
                            <div style={{ marginRight: '10px' }}>{highlightMatch(suggestion.cooking_methods.best, value)}</div>
                        </div>
                        <div>
                            <ul style={{ listStyleType: 'none', padding: 0, margin: 0, display: 'flex', flexWrap: 'wrap' }}>
                                {suggestion.ingredient_liste.map((ingredient, index) => (
                                    <span key={index} style={{ marginRight: '10px' }}>{highlightMatch(ingredient.replace(/-/g, ' '), value)},</span>
                                ))}
                            </ul>
                        </div>
                    </Card.Text>
                    <Card.Link as="a" style={{ paddingLeft: "10px", color: "#454545", cursor :"pointer" }}>
                        <FaImages />
                        &nbsp;Voir la recette
                    </Card.Link>
                </Card.Body>
            </Card>
        </Col>
    );

    const inputProps = {
        placeholder: 'Rechercher une recette par mot clé.',
        value,
        onChange: onChange,
        style: { width: '100%', height: '50px', padding: '10px', borderRadius: "10px", background: "#fff" }
    };

    const onSuggestionSelected = (_, { suggestion }) => {
        window.location.href = suggestion.detailURL;
    };

    return (
        <form onSubmit={onSubmit} style={{ marginTop: "30px" }}>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={({ value }) => setSuggestions(getSuggestions(value))}
                onSuggestionsClearRequested={() => setSuggestions([])}
                getSuggestionValue={suggestion => suggestion.title}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={onSuggestionSelected}
            />
            <Button variant="success" type="submit" className="search-icon" style={{ zIndex:"0",marginLeft: "10px", height: "50px", width: "50px" }}>
                <FaSearch />
            </Button>
        </form>
    );
};

export default RecipeSelector;
