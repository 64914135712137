import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './ScrollToTop.css';
import logo from  "../Medias/poulet-sans-fond-lettrage-b.png";

function ScrollToTop({ history }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unlisten = history.listen(() => {
      setLoading(true);  // Active l’overlay avec le logo
      setTimeout(() => {
        //window.scrollTo(0, 0);  // Défilement vers le haut de la page
        setLoading(false);      // Désactive l’overlay après le scroll
      }, 700);                  // Durée avant la disparition de l'overlay
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return (
      <>
        {loading && (
            <div className="overlay">
              <img src={logo} alt="Logo" className="overlay-logo" /> {/* Logo dans l'overlay */}
            </div>
        )}
      </>
  );
}

export default withRouter(ScrollToTop);
