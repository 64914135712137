import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker"; // Assurez-vous que ce chemin est correct

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

// Si vous voulez que votre application fonctionne hors ligne et se charge plus rapidement,
// vous pouvez changer unregister() pour register() ci-dessous.
// Cela comporte quelques pièges.
// En savoir plus sur les service workers : https://bit.ly/CRA-PWA
serviceWorker.register();  // Activer le Service Worker
