import React, { useState, useEffect } from 'react';
import { FaShareAlt } from 'react-icons/fa';
import gridSet from "../Medias/Gallery_0/GridSet";

const ShareComponent = () => {
    const [showFallback, setShowFallback] = useState(false);
    const [shareStatus, setShareStatus] = useState(null);
    const [currentRecipe, setCurrentRecipe] = useState(null);

    useEffect(() => {
        // Extraire le chemin de l'URL actuelle sans le domaine
        const currentPath = window.location.pathname;
        // Rechercher dans gridSet l'objet qui correspond au chemin relatif de l'URL actuelle
        const foundRecipe = gridSet.find(item => item.detailURL === currentPath);
        // Mettre à jour l'état avec l'objet trouvé
        foundRecipe && setCurrentRecipe(foundRecipe);
    }, []);

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: currentRecipe?.title || 'Marinade poulet PWA !',
                    text: currentRecipe?.shortStory || 'Découvrez cette recette fantastique !',
                    url: window.location.href,
                });
                setShareStatus(''); // Message de succès
            } catch (error) {
                setShareStatus(''); // Message d'erreur
            }
        } else {
            setShowFallback(true); // Si l'API Web Share n'est pas disponible
        }
    };

    const currentUrl = encodeURIComponent(window.location.href);
    const shareText = encodeURIComponent(currentRecipe?.shortStory || 'Découvrez cette recette fantastique !');

    return (
        <>
            <button onClick={handleShare} title="Partager" style={{ marginTop: "-8px" }}>
                <FaShareAlt style={{ fontSize: "20px", color: "#2196F3" }} />
                <br />
                <sup style={{ fontSize: "10px", color: "#2196F3" }}>Partager</sup>
            </button>

            {/* Affichage du message de succès ou d'erreur */}
            {shareStatus && <p>{shareStatus}</p>}

            {showFallback && (
                <div id="fallbackShare">
                    <p>L'API de partage n'est pas disponible. Utilisez ces options :</p>
                    <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Partager sur Facebook
                    </a>
                    <a
                        href={`https://twitter.com/intent/tweet?url=${currentUrl}&text=${shareText}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Partager sur Twitter
                    </a>
                </div>
            )}
        </>
    );
};

export default ShareComponent;
