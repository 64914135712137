import React, { useEffect, useState } from "react";
import {  Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaBookmark, FaTimes } from "react-icons/fa";  // Icône de favoris et croix
import './Share.css'; // Assurez-vous que ce fichier existe pour les styles de partage

const BookmarksSelectHistory = () => {
    const [favoriteRecipes, setFavoriteRecipes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedRecipeTitle, setSelectedRecipeTitle] = useState("Mes enregistrements"); // Titre par défaut

    // Charger les recettes favorites depuis le localStorage au montage du composant
    useEffect(() => {
        // Charger les recettes favorites depuis le localStorage lors du montage
        const storedFavorites = JSON.parse(localStorage.getItem('favoriteRecipes')) || [];
        setFavoriteRecipes(storedFavorites);
    }, []); // Pas de dépendances pour éviter la boucle infinie

    const handleBookmarkClick = (recipe) => {
        let updatedFavorites = [...favoriteRecipes];
        const recipeExists = updatedFavorites.find(fav => fav.id === recipe.id);

        if (recipeExists) {
            updatedFavorites = updatedFavorites.filter(fav => fav.id !== recipe.id);
        } else {
            updatedFavorites.push(recipe);
        }

        setFavoriteRecipes(updatedFavorites); // Mettre à jour l'état local
        localStorage.setItem('favoriteRecipes', JSON.stringify(updatedFavorites)); // Mettre à jour le localStorage
    };

    // Ouvrir et fermer la modal
    const handleShow = () => {
        // Charger les favoris du localStorage au moment de l'ouverture de la modal
        const storedFavorites = JSON.parse(localStorage.getItem('favoriteRecipes')) || [];
        setFavoriteRecipes(storedFavorites);
        setShowModal(true);
    };
    const handleClose = () => setShowModal(false);

    // Gérer la sélection d'une recette et réinitialiser la sélection
    const handleRecipeClick = (recipeTitle) => {
        setSelectedRecipeTitle("Mes enregistrements"); // Réinitialise le titre après sélection
        handleClose(); // Ferme la modal
    };

    return (
        <>
            {/* Affichage de l'icône de favoris si des recettes sont présentes */}
            <button onClick={handleShow} title="Partager" style={{marginTop:"-8px"}}>
                <FaBookmark style={{fontSize:"20px",color:"#689F38"}} />
                <br/>
                <sup style={{fontSize:"10px", color:"#689F38"}}>{selectedRecipeTitle}</sup>
            </button>

            {/* Modal pour afficher les recettes favorites */}
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Mes enregistrements</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {favoriteRecipes.length > 0 ? (
                        favoriteRecipes.map((recipe, index) => (
                            <div key={index} style={{ display: "block", padding: "10px 0", color: "#333" }}>
                                <Link
                                    to={recipe.detailURL}
                                    style={{ textDecoration: "none", color: "#333" }}
                                    onClick={() => handleRecipeClick(recipe.title)}
                                >
                                    {recipe.title}
                                </Link>

                                {/* Icône de croix pour retirer la recette des favoris */}
                                <FaTimes
                                    style={{
                                        cursor: 'pointer',
                                        color: '#e74c3c',
                                        fontSize: '18px',
                                        marginLeft: '10px',
                                    }}
                                    onClick={() => handleBookmarkClick(recipe)} // Supprimer la recette des favoris
                                />
                            </div>
                        ))
                    ) : (
                        <p>Vous n'avez enregistré aucune recette pour le moment.</p>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default BookmarksSelectHistory;
