import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Footer = () => { // Ajout de la prop pour afficher les préférences cookies
    return (
        <div
            className="ml-auto fixed-bottom"
            style={{ color: "#888", fontSize: "12px", backgroundColor: "#111111", textAlign: "right" }}
        >
            &nbsp;&nbsp;<b>©2024 marinade-poulet.com</b>&nbsp;&nbsp;&nbsp;
        </div>
    );
};

export default Footer;
