const GridSet = [

    {
        "id": 54,
        "categorie": "moyen-orient",
        "country": "Egypte",
        "title": "Poulet au Citron et aux Épices",
        "subTitle": "Poulet tendre et juteux avec une touche de citron",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 cuisses de poulet",
            "1 citron",
            "2 cuillères à soupe de mélange d'épices (cumin, coriandre, paprika)",
            "2 cuillères à soupe d'huile d'olive",
            "Sel et poivre"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "citron",
            "mélange-d'épices",
            "huile-d'olive",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Presser le citron et mélanger le jus avec les épices et l'huile d'olive.",
            "Badigeonner les cuisses de poulet avec la marinade.",
            "Laisser mariner pendant au moins 1 heure."
        ],
        "cuisson": [
            "Griller ou cuire à la plancha jusqu'à ce que le poulet soit bien cuit."
        ],
        "cooking_methods": {
            "best": "grill",
            "plancha": true,
            "friture": false,
            "barbecue": false
        },
        "mode_cuisson": ["grill", "plancha"],
        "accompagnement": "Servir avec du riz basmati.",
        "shortStory": "Le Poulet au Citron et aux Épices est un plat savoureux et rafraîchissant, mettant en valeur des saveurs vives grâce au citron et aux épices. Ce plat est parfait pour une soirée estivale et accompagne merveilleusement le riz basmati.",
        "detailURL": "/fiche-recette/54/poulet-citron-epices/poulet-citron-epices.html",
        "time_prepare": "10mn",
        "time_maceration": "1h",
        "time_cooking": "25mn",
        "crispys": false
    }
    ,
    {
        "id": 2,
        "categorie": "asiatique",
        "country": "Chine",
        "title": "Poulet général Tao",
        "subTitle": "Poulet croustillant sauce aigre-douce",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "blanc": true,
            "cuisse": false,
            "aile": false,
            "pilon": false
        },
        "ingredient": [
            "4 filets de poulet",
            "1/2 tasse de sauce soja",
            "1/4 tasse de miel",
            "2 cuillères à soupe de vinaigre de riz",
            "2 gousses d'ail émincées",
            "1 cuillère à café de gingembre frais râpé",
            "2 cuillères à soupe de fécule de maïs",
            "1/4 tasse d'eau",
            "2 cuillères à soupe d'huile végétale",
            "2 cuillères à soupe d'huile de sésame",
            "1 cuillère à soupe de graines de sésame (pour la garniture)",
            "Tranches de piment rouge (pour la garniture)",
            "Tranches de cébette (pour la garniture)"
        ],
        "ingredient_liste": [
            "filets-de-poulet",
            "sauce-soja",
            "miel",
            "vinaigre-de-riz",
            "ail-eminces",
            "gingembre-frais",
            "fecule-de-mais",
            "eau",
            "huile-vegetale",
            "huile-de-sesame",
            "graines-de-sesame",
            "piment-rouge",
            "cebettes"
        ],
        "preparation": [
            "Dans un bol, mélanger la sauce soja, le miel, le vinaigre de riz, l'ail et le gingembre pour faire la sauce.",
            "Couper les filets de poulet en morceaux de taille moyenne et les enrober de fécule de maïs."
        ],
        "cuisson": [
            "Chauffer l'huile végétale dans une poêle à feu moyen-élevé. Faire frire les morceaux de poulet jusqu'à ce qu'ils soient dorés et croustillants. Retirer et égoutter sur du papier absorbant.",
            "Dans une autre poêle, chauffer l'huile de sésame à feu moyen.",
            "Ajouter la sauce préparée et l'eau. Laisser mijoter jusqu'à ce que la sauce épaississe légèrement.",
            "Ajouter les morceaux de poulet frits dans la poêle avec la sauce et mélanger pour les enrober uniformément.",
        ],
        "cooking_methods": {
            "best": "friture",
            "plancha": false,
            "four": false,
            "barbecue": false
        },
        "mode_cuisson": ["friture"],
        "accompagnement": "Servir le poulet général Tao chaud, saupoudré de graines de sésame et garni de tranches de piment rouge et de cébette. Le poulet général Tao est délicieux servi avec du riz cuit à la vapeur et des légumes sautés à l'ail et au gingembre.",
        "shortStory": "Le poulet général Tao est un plat populaire de la cuisine chinoise, apprécié pour son poulet croustillant enrobé d'une sauce aigre-douce savoureuse. Cette recette maison vous permet de savourer ce plat délicieux et authentique dans le confort de votre foyer.",
        "detailURL": "/fiche-recette/2/poulet-general-tao-sauce-soja-miel-vinaigre-riz-gingembre-fecule-mais/poulet-general-tao.html",
        "time_prepare": "20mn",
        "time_cooking": "20mn",
        "time_maceration": "0mn",
        "crispys": true
    },
    {
        "id": 3,
        "categorie": "afrique",
        "country": "Sénégal",
        "title": "Poulet Yassa",
        "subTitle": "Poulet mariné à l'oignon et au citron",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "blanc": false,
            "cuisse": true,
            "aile": false,
            "pilon": false
        },
        "ingredient": [
            "4 cuisses de poulet",
            "6 oignons moyens, tranchés en rondelles",
            "4 citrons, jus",
            "4 gousses d'ail, écrasées",
            "4 cuillères à soupe d'huile d'olive",
            "2 cuillères à soupe de moutarde de Dijon",
            "2 cuillères à soupe de vinaigre blanc",
            "2 cuillères à soupe de sauce soja",
            "2 cuillères à soupe de persil frais, haché",
            "1 cuillère à café de poivre noir",
            "Sel, au goût"
        ],
        "ingredient_liste": [
            "cuisse-de-poulet",
            "oignons",
            "citrons",
            "ail",
            "huile-dolive",
            "moutarde-de-dijon",
            "vinaigre-blanc",
            "sauce-soja",
            "persil-frais",
            "poivre-noir",
            "sel"
        ],
        "preparation": [
            "Dans un grand bol, mélanger le jus de citron, l'ail écrasé, l'huile d'olive, la moutarde de Dijon, le vinaigre blanc, la sauce soja, le persil haché, le poivre noir et le sel.",
            "Ajouter les cuisses de poulet dans le mélange de marinade, en veillant à bien les enrober. Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute la nuit.",

        ],
        "cuisson": [
            "Dans une grande poêle, chauffer un peu d'huile d'olive à feu moyen-élevé.",
            "Ajouter les oignons tranchés et les faire revenir jusqu'à ce qu'ils soient tendres et légèrement caramélisés.",
            "Ajouter les cuisses de poulet marinées dans la poêle avec les oignons et faire cuire à feu moyen pendant environ 30 à 40 minutes, en retournant les cuisses de temps en temps, jusqu'à ce qu'elles soient bien cuites et dorées.",
            "Servir le poulet Yassa chaud, garni d'oignons caramélisés."
        ],
        "cooking_methods": {
            "best": "poêle",
            "plancha": false,
            "four": false,
            "barbecue": false
        },
        "mode_cuisson": ["poêle"],
        "accompagnement": "Le poulet Yassa est traditionnellement servi avec du riz blanc ou du couscous, ainsi que des légumes sautés ou une salade verte.",
        "shortStory": "Le poulet Yassa est un plat traditionnel sénégalais, réputé pour sa marinade à base d'oignon, de citron et d'épices. Cette recette délicieusement parfumée est facile à préparer et constitue un repas copieux et réconfortant pour toute la famille.",
        "detailURL": "/fiche-recette/3/poulet-yassa-cuisse-poulet-oignons-citron-marinade/poulet-yassa.html",
        "time_prepare": "15mn",
        "time_maceration": "2h",
        "time_cooking": "40mn",

        "crispys": false
    }, {
        "id": 4,
        "categorie": "europe",
        "country": "France",
        "title": "Coq au Vin",
        "subTitle": "Poulet mijoté au vin rouge",
        "chicken_piece": ["entier"],
        "piece": {
            "best": "entier",
            "poulet_entier": true,
            "blanc": false,
            "cuisse": false,
            "aile": false,
            "pilon": false
        },
        "ingredient": [
            "1 poulet entier, coupé en morceaux",
            "150g de lardons fumés",
            "200g de champignons de Paris, tranchés",
            "2 oignons, émincés",
            "4 gousses d'ail, émincées",
            "2 carottes, coupées en rondelles",
            "2 branches de céleri, coupées en dés",
            "1 bouteille de vin rouge",
            "500ml de bouillon de poulet",
            "2 cuillères à soupe de farine",
            "2 cuillères à soupe de beurre",
            "2 cuillères à soupe d'huile d'olive",
            "1 bouquet garni (thym, laurier, persil)",
            "Sel et poivre, au goût"
        ],
        "ingredient_liste": [
            "poulet-entier",
            "lardons-fumes",
            "champignons-de-paris",
            "oignons",
            "ail",
            "carottes",
            "celeri",
            "vin-rouge",
            "bouillon-de-poulet",
            "farine",
            "beurre",
            "huile-dolive",
            "thym",
            "laurier",
            "persil",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Dans une grande cocotte, faire chauffer l'huile d'olive et le beurre à feu moyen-élevé. Ajouter les morceaux de poulet et les lardons, et les faire dorer de tous les côtés.",
            "Retirer le poulet et les lardons de la cocotte et réserver. Ajouter les oignons, l'ail, les carottes et le céleri dans la cocotte, et les faire revenir jusqu'à ce qu'ils soient tendres.",
            "Remettre le poulet et les lardons dans la cocotte. Saupoudrer de farine et bien mélanger.",
            "Verser le vin rouge dans la cocotte et porter à ébullition. Ajouter le bouillon de poulet et le bouquet garni. Assaisonner avec du sel et du poivre, au goût.",
            "Couvrir la cocotte et laisser mijoter à feu doux pendant environ 1h30 à 2 heures, ou jusqu'à ce que le poulet soit tendre et le liquide réduit.",
            "Environ 30 minutes avant la fin de la cuisson, ajouter les champignons tranchés dans la cocotte.",
            "Servir le coq au vin chaud, accompagné de pommes de terre vapeur ou de purée de pommes de terre."
        ],
        "cuisson": [
            "Faire mijoter le coq au vin à feu doux dans une cocotte pendant environ 1h30 à 2 heures."
        ],
        "cooking_methods": {
            "best": "cocotte",
            "plancha": false,
            "four": false,
            "barbecue": false
        },
        "mode_cuisson": ["cocotte"],
        "accompagnement": "Le coq au vin est traditionnellement servi avec des pommes de terre vapeur, de la purée de pommes de terre ou des pâtes fraîches, ainsi qu'une salade verte.",
        "shortStory": "Le coq au vin est un plat classique de la cuisine française, apprécié pour sa viande tendre et son délicieux sauce au vin rouge. Cette recette traditionnelle est parfaite pour les repas en famille ou les occasions spéciales.",
        "detailURL": "/fiche-recette/4/coq-au-vin-poulet-lardons-champignons-vin-rouge/coq-au-vin.html",
        "time_prepare": "30mn",
        "time_cooking": "2h",
        "time_maceration": "0mn",
        "crispys": false
    },
    {
        "id": 5,
        "categorie": "amerique-sud",
        "country": "Pérou",
        "title": "Poulet à la Péruvienne",
        "subTitle": "Poulet mariné aux épices péruviennes",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "cuisse": true,
            "blanc": false,
            "aile": false,
            "pilon": false
        },
        "ingredient": [
            "4 cuisses de poulet",
            "4 cuillères à soupe de sauce soja",
            "2 cuillères à soupe de vinaigre de cidre",
            "2 cuillères à soupe de jus de citron vert",
            "2 cuillères à soupe d'huile d'olive",
            "2 cuillères à soupe de paprika",
            "1 cuillère à soupe d'origan séché",
            "1 cuillère à soupe de cumin moulu",
            "1 cuillère à café de piment de Cayenne",
            "4 gousses d'ail, émincées",
            "Sel et poivre, au goût"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "sauce-soja",
            "vinaigre-de-cidre",
            "jus-de-citron-vert",
            "huile-dolive",
            "paprika",
            "origan-seche",
            "cumin-moulu",
            "piment-de-cayenne",
            "ail",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Dans un bol, mélanger la sauce soja, le vinaigre de cidre, le jus de citron vert, l'huile d'olive, le paprika, l'origan, le cumin, le piment de Cayenne, l'ail émincé, le sel et le poivre pour faire la marinade.",
            "Ajouter les cuisses de poulet dans le bol et bien les enrober de marinade. Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement toute une nuit."
        ],
        "cuisson": [
            "Préchauffer le barbecue à feu moyen-élevé.",
            "Retirer les cuisses de poulet de la marinade et les disposer sur la grille du barbecue.",
            "Faire griller les cuisses de poulet feu moyen-élevé pendant environ 20 à 25 minutes de chaque côté, ou jusqu'à ce qu'elles soient bien cuites et dorées."
        ],
        "cooking_methods": {
            "best": "barbecue",
            "plancha": false,
            "four": false,
            "grill": false
        },
        "mode_cuisson": ["barbecue"],
        "accompagnement":
            "Servir le poulet à la péruvienne chaud, accompagné de riz blanc des pommes de terre rôties ou et de légumes grillés et accompagné d'une sauce piquante péruvienne.",
        "shortStory": "Le poulet à la péruvienne est un plat péruvien populaire, réputé pour sa marinade aux épices exotiques et son grillé savoureux. Cette recette simple et savoureuse vous transporte au cœur de la cuisine péruvienne, avec ses saveurs riches et épicées.",
        "detailURL": "/fiche-recette/5/poulet-peruvienne-sauce-soja-vinaigre-cidre-jus-citron-vert/poulet-peruvienne.html",
        "time_prepare": "15mn",
        "time_maceration": "2h",
        "time_cooking": "45mn",
        "crispys": false
    },
    {
        "id": 6,
        "categorie": "asiatique",
        "country": "Japon",
        "title": "Yakitori de poulet",
        "subTitle": "Brochettes de poulet japonaises",
        "chicken_piece": ["aile"],
        "piece": {
            "best": "aile",
            "entier": false,
            "aile": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "500g d'ailes de poulet",
            "6 cuillères à soupe de sauce soja",
            "4 cuillères à soupe de mirin (ou 2 cuillères à soupe de vinaigre de riz et 2 cuillères à soupe de sucre)",
            "2 cuillères à soupe de saké (ou 1 cuillère à soupe de vinaigre de riz)",
            "2 gousses d'ail, émincées",
            "1 cuillère à café de gingembre frais râpé",
            "2 cuillères à soupe de miel (facultatif)",
            "6 à 8 brochettes en bois (trempées dans l'eau)"
        ],
        "ingredient_liste": [
            "ailes-de-poulet",
            "sauce-soja",
            "mirin",
            "sake",
            "ail",
            "gingembre-frais",
            "miel",
            "brochettes-en-bois"
        ],
        "preparation": [
            "Dans un bol, mélanger la sauce soja, le mirin, le saké, l'ail, le gingembre et éventuellement le miel pour faire la marinade.",
            "Ajouter les ailes de poulet dans le bol et bien les enrober de marinade. Couvrir et laisser mariner au réfrigérateur pendant au moins 1 heure, idéalement toute une nuit.",
            "Enfiler les ailes de poulet marinées sur les brochettes en bois préalablement trempées dans l'eau."
        ],
        "cuisson": [
            "Faire griller les brochettes de poulet sur un barbecue ou sous le gril du four pendant environ 10 à 15 minutes de chaque côté, ou jusqu'à ce qu'elles soient bien cuites et caramélisées."
        ],
        "cooking_methods": {
            "best": "four",
            "plancha": false,
            "four": true,
            "grill": false
        },
        "mode_cuisson": ["four"],
        "accompagnement": "Les yakitoris sont délicieux servis avec du riz blanc ou des nouilles soba, et arrosés de sauce teriyaki ou de sauce yakitori.",
        "shortStory": "Les yakitoris sont des brochettes de poulet japonaises savoureuses et tendres, marinées dans une sauce sucrée-salée et grillées pour une texture caramélisée irrésistible. Ce plat populaire est idéal pour une soirée barbecue ou un repas décontracté entre amis.",
        "detailURL": "/fiche-recette/6/yakitoris-poulet-sauce-soja-mirin-sake/yakitori.html",
        "time_prepare": "15mn",
        "time_maceration": "1h",
        "time_cooking": "20mn",
        "crispys": false
    },
    {
        "id": 7,
        "categorie": "amerique-nord",
        "country": "États-Unis",
        "title": "Poulet frit croustillant",
        "subTitle": "Poulet pané et frit à la perfection",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 cuisses de poulet",
            "2 tasses de babeurre",
            "2 cuillères à soupe de sauce piquante (facultatif)",
            "2 tasses de farine tout usage",
            "1 cuillère à soupe de paprika",
            "1 cuillère à soupe de sel",
            "1 cuillère à soupe de poivre noir",
            "1 cuillère à café de poudre d'ail",
            "1 cuillère à café de poudre d'oignon",
            "Huile végétale pour la friture"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "babeurre",
            "sauce-piquante",
            "farine-tout-usage",
            "paprika",
            "sel",
            "poivre-noir",
            "poudre-ail",
            "poudre-oinion",
            "huile-vegetale"
        ],
        "preparation": [
            "Dans un grand bol, mélanger le babeurre et la sauce piquante (si désiré). Ajouter les cuisses de poulet dans le mélange et laisser mariner pendant au moins 1 heure au réfrigérateur.",
            "Dans un autre bol, mélanger la farine, le paprika, le sel, le poivre, la poudre d'ail et la poudre d'oignon.",
        ],
        "cuisson": [
            "Préchauffer l'huile végétale dans une grande poêle à feu moyen-élevé.",
            "Retirer les cuisses de poulet de la marinade et les enrober uniformément du mélange de farine.",
            "Faire frire les cuisses de poulet dans l'huile chaude pendant environ 15 à 20 minutes de chaque côté, ou jusqu'à ce qu'elles soient dorées et cuites à cœur.",
            "Égoutter le poulet frit sur du papier absorbant pour éliminer l'excès d'huile."
        ],
        "cooking_methods": {
            "best": "friture",
            "plancha": false,
            "four": false,
            "grill": false
        },
        "mode_cuisson": ["friture"],
        "accompagnement": "Le poulet frit croustillant est délicieux avec des frites maison, de la salade de chou, des biscuits chauds ou du maïs grillé.",
        "shortStory": "Le poulet frit croustillant est un plat classique de la cuisine américaine, apprécié pour sa croûte croustillante et sa viande tendre et juteuse à l'intérieur. Cette recette simple et réconfortante est parfaite pour un repas en famille ou une soirée entre amis.",
        "detailURL": "/fiche-recette/7/poulet-frit-croustillant-cuisses-babeurre/frit-croustillant.html",
        "time_prepare": "15mn",
        "time_maceration": "1h",
        "time_cooking": "40mn",

        "crispys": true
    },
    {
        "id": 8,
        "categorie": "europe",
        "country": "France",
        "title": "Poulet à la Normande",
        "subTitle": "Poulet mijoté dans une sauce crémeuse aux pommes et au cidre",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 cuisses de poulet",
            "2 pommes, pelées, épépinées et coupées en quartiers",
            "1 oignon, émincé",
            "2 gousses d'ail, émincées",
            "1 tasse de cidre brut",
            "1/2 tasse de crème fraîche",
            "2 cuillères à soupe de beurre",
            "2 cuillères à soupe de farine tout usage",
            "1 cuillère à soupe de persil frais haché",
            "Sel et poivre, au goût"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "pommes",
            "oignon",
            "ail",
            "cidre-brut",
            "creme-fraiche",
            "beurre",
            "farine-tout-usage",
            "persil-frais",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Dans une grande poêle ou une cocotte, faire fondre le beurre à feu moyen-élevé. Ajouter les cuisses de poulet et les faire dorer de chaque côté pendant quelques minutes. Retirer le poulet de la poêle et réserver.",
            "Dans la même poêle, ajouter l'oignon émincé et les quartiers de pommes. Faire sauter pendant quelques minutes jusqu'à ce qu'ils soient légèrement dorés.",
            "Ajouter l'ail émincé et faire cuire pendant une minute de plus.",
            "Saupoudrer de farine tout usage sur les pommes et les oignons, et remuer pour bien enrober.",
            "Verser le cidre brut dans la poêle et gratter le fond pour décoller les morceaux dorés. Laisser mijoter pendant quelques minutes jusqu'à ce que le cidre réduise de moitié.",
            "Remettre les cuisses de poulet dans la poêle et ajouter la crème fraîche.",
            "Assaisonner avec du sel et du poivre selon votre goût."
        ],
        "cuisson": [
            "Laisser mijoter à feu doux pendant environ 20 à 25 minutes, ou jusqu'à ce que le poulet soit bien cuit et que la sauce soit onctueuse."
        ],
        "cooking_methods": {
            "best": "cocotte",
            "plancha": false,
            "four": false,
            "grill": false
        },
        "mode_cuisson": ["cocotte"],
        "accompagnement": "Saupoudrer de persil frais haché avant de servir. Le poulet à la Normande est délicieux accompagné de pommes de terre rôties, de riz ou de purée de pommes de terre. Une salade verte fraîche complète parfaitement ce plat réconfortant.",
        "shortStory": "Le poulet à la Normande est une recette traditionnelle de la cuisine française, originaire de la région de Normandie. Ce plat réconfortant marie le poulet tendre et juteux avec une sauce crémeuse aux pommes et au cidre, pour une explosion de saveurs délicieusement riches et réconfortantes.",
        "detailURL": "/fiche-recette/8/poulet-normande-pommes-cidre/poulet-normande.html",
        "time_prepare": "20mn",
        "time_maceration": "0mn",
        "time_cooking": "40mn",
        "crispys": false
    },
    {
        "id": 12,
        "categorie": "amerique-sud",
        "country": "Brésil",
        "title": "Poulet à la Brésilienne",
        "subTitle": "Marinade exotique aux saveurs tropicales",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 cuisses de poulet",
            "1/4 tasse de jus de citron vert",
            "1/4 tasse de sauce soja",
            "2 cuillères à soupe d'huile d'olive",
            "2 gousses d'ail émincées",
            "2 cuillères à café de paprika",
            "1 cuillère à café de cumin moulu",
            "1 cuillère à café de coriandre moulue",
            "1 cuillère à café de piment de Cayenne (facultatif pour un goût plus épicé)",
            "Sel et poivre, au goût"
        ],
        "ingredient_liste": [
            "cuisse-de-poulet",
            "jus-de-citron-vert",
            "sauce-soja",
            "huile-d'olive",
            "ail-eminces",
            "paprika",
            "cumin-moulu",
            "coriandre-moulue",
            "piment-de-cayenne",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Dans un grand bol, mélanger le jus de citron vert, la sauce soja, l'huile d'olive, l'ail émincé, le paprika, le cumin moulu, la coriandre moulue et le piment de Cayenne (si utilisé).",
            "Ajouter les cuisses de poulet dans le bol et bien les enrober de marinade.",
            " Couvrir le bol et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute une nuit."],
        "cuisson": [
            "Préchauffer le grill à feu moyen-élevé. Retirer les cuisses de poulet de la marinade et jeter l'excès de marinade.",
            "Griller les cuisses de poulet sur le barbecue pendant environ 20 à 25 minutes, en les retournant de temps en temps, jusqu'à ce qu'elles soient bien cuites et dorées.",
            "Servir chaud avec des quartiers de citron vert et des tranches d'ananas grillées pour une touche tropicale supplémentaire."
        ],
        "cooking_methods": {
            "best": "barbecue",
            "plancha": false,
            "four": false,
            "grill": false
        },
        "mode_cuisson": ["barbecue"],
        "accompagnement": "Le poulet à la Brésilienne est délicieux accompagné de riz blanc, de haricots noirs et d'une salade fraîche de tomates et de concombres.",
        "shortStory": "Le poulet à la Brésilienne est une explosion de saveurs tropicales, parfaite pour les grillades estivales ou les fêtes en plein air. La marinade exotique à base de jus de citron vert, de sauce soja et d'épices brésiliennes traditionnelles donne au poulet une saveur incroyablement savoureuse et un peu piquante. Un plat incontournable pour une expérience culinaire authentique du Brésil !",
        "detailURL": "/fiche-recette/12/poulet-bresilienne-cuisse-jus-citron-vert-sauce-soja/poulet-bresilienne.html",
        "time_prepare": "15mn",
        "time_maceration": "2h",
        "time_cooking": "25mn",

        "crispys": false
    },
    {
        "id": 13,
        "categorie": "amerique-nord",
        "country": "États-Unis",
        "title": "Poulet BBQ du Sud",
        "subTitle": "Marinade maison style barbecue du Sud des États-Unis",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "pilon": false,
            "manchon": false,
            "cuisse": false,
            "poitrine": true
        },
        "ingredient": [
            "4 poitrines de poulet désossées et sans peau",
            "1 tasse de sauce barbecue",
            "1/4 tasse de vinaigre de cidre de pomme",
            "2 cuillères à soupe de miel",
            "2 gousses d'ail émincées",
            "1 cuillère à café de paprika fumé",
            "1 cuillère à café de poudre d'oignon",
            "1/2 cuillère à café de poivre noir",
            "1/2 cuillère à café de sel"
        ],
        "ingredient_liste": [
            "poitrine-de-poulet",
            "sauce-barbecue",
            "vinaigre-de-cidre-de-pomme",
            "miel",
            "ail-eminces",
            "paprika-fume",
            "poudre-d'oignon",
            "poivre-noir",
            "sel"
        ],
        "preparation": [
            "Dans un bol, mélanger la sauce barbecue, le vinaigre de cidre de pomme, le miel, l'ail émincé, le paprika fumé, la poudre d'oignon, le poivre noir et le sel pour faire la marinade.",
            "Ajouter les poitrines de poulet dans le bol et bien les enrober de marinade.",
            "Couvrir le bol et laisser mariner au réfrigérateur pendant au moins 4 heures, de préférence toute une nuit."
        ],
        "cuisson": [
            "Préchauffer le grill à feu moyen-élevé.",
            "Retirer les poitrines de poulet de la marinade et jeter l'excès de marinade.",
            "Griller les poitrines de poulet sur le barbecue pendant environ 6 à 8 minutes de chaque côté, jusqu'à ce qu'elles soient bien cuites et que la marinade forme une croûte caramélisée.",
            "Laisser reposer les poitrines de poulet grillées pendant quelques minutes avant de les servir.",
            "Garnir éventuellement de coriandre fraîche hachée pour plus de saveur."
        ],
        "cooking_methods": {
            "best": "barbecue",
            "plancha": false,
            "four": false,
            "grill": false
        },
        "mode_cuisson": ["barbecue"],
        "accompagnement": "Le poulet BBQ du Sud est délicieux servi avec des épis de maïs grillés, des haricots verts sautés et une salade de chou crémeuse.",
        "shortStory": "Le poulet BBQ du Sud est une spécialité emblématique des États-Unis, particulièrement appréciée dans le Sud. Cette marinade maison, riche en saveurs fumées et sucrées, est parfaite pour donner aux poitrines de poulet une tendreté et une saveur incomparables. Idéal pour les grillades en plein air et les rassemblements entre amis !",
        "detailURL": "/fiche-recette/13/poulet-bbq-sud-poitrine-sauce-barbecue-miel/poulet-bbq-sud.html",
        "time_prepare": "15mn",
        "time_maceration": "4h",
        "time_cooking": "15mn",
        "crispys": false
    },
    {
        "id": 14,
        "categorie": "europe",
        "country": "Grèce",
        "title": "Souvlaki de Poulet Grec",
        "subTitle": "Brochettes de poulet marinées à la grecque",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "pilon": false,
            "manchon": false,
            "cuisse": false,
            "poitrine": true
        },
        "ingredient": [
            "4 filets de poulet",
            "1/4 tasse d'huile d'olive extra vierge",
            "3 cuillères à soupe de jus de citron",
            "2 gousses d'ail émincées",
            "1 cuillère à café d'origan séché",
            "1 cuillère à café de paprika",
            "1/2 cuillère à café de sel",
            "1/4 cuillère à café de poivre noir",
            "Yaourt grec nature pour servir (facultatif)",
            "Pain pita pour servir (facultatif)",
            "Laitue, tomate, oignon rouge tranché pour servir (facultatif)"
        ],
        "ingredient_liste": [
            "filet-de-poulet",
            "huile-d'olive-extra-vierge",
            "jus-de-citron",
            "ail-eminces",
            "origan-seche",
            "paprika",
            "sel",
            "poivre-noir",
            "yaourt-grec-nature",
            "pain-pita",
            "laitue",
            "tomate",
            "oignon-rouge-tranche"
        ],
        "preparation": [
            "Dans un bol, mélanger l'huile d'olive, le jus de citron, l'ail émincé, l'origan séché, le paprika, le sel et le poivre noir pour faire la marinade.",
            "Couper les filets de poulet en cubes et les ajouter à la marinade.",
            "Bien enrober le poulet de marinade. Couvrir le bol et laisser mariner au réfrigérateur pendant au moins 1 heure, idéalement toute une nuit.",
            "Enfiler les cubes de poulet marinés sur des brochettes en bois ou en métal."
        ],
        "cuisson": [
            "Préchauffer le grill à feu moyen-élevé.",
            "Retirer les brochettes de poulet de la marinade et jeter l'excès de marinade.",
            "Griller les brochettes de poulet sur le barbecue pendant environ 8 à 10 minutes, en les retournant de temps en temps, jusqu'à ce qu'elles soient bien cuites et dorées.",
            "Servir chaud avec du yaourt grec nature, du pain pita chaud et des légumes frais, si désiré."
        ],
        "cooking_methods": {
            "best": "barbecue",
            "plancha": false,
            "four": false,
            "grill": false
        },
        "mode_cuisson": ["barbecue"],
        "accompagnement": "Le souvlaki de poulet grec est traditionnellement servi avec du pain pita, du yaourt grec, des légumes frais et une salade grecque.",
        "shortStory": "Le souvlaki de poulet grec est un plat emblématique de la cuisine grecque, apprécié pour sa simplicité et ses saveurs authentiques. Marinées dans un mélange d'huile d'olive, de jus de citron et d'épices méditerranéennes, les brochettes de poulet sont grillées à la perfection, créant un plat délicieusement parfumé qui ravira vos papilles !",
        "detailURL": "/fiche-recette/14/souvlaki-poulet-grec-filet-huile-olive-citron/souvlaki-poulet-grec.html",
        "time_prepare": "15mn",
        "time_maceration": "1h",
        "time_cooking": "10mn",

        "crispys": false
    }
    ,
    {
        "id": 15,
        "categorie": "amerique-nord",
        "country": "États-Unis",
        "title": "Ailes de Poulet Buffalo",
        "subTitle": "Ailes de poulet épicées et croustillantes",
        "chicken_piece": ["aile"],
        "piece": {
            "best": "aile",
            "entier": false,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "12 ailes de poulet",
            "1/2 tasse de sauce piquante style Buffalo",
            "1/4 tasse de beurre fondu",
            "1 cuillère à soupe de vinaigre blanc",
            "1/2 cuillère à café de poudre d'ail",
            "1/2 cuillère à café de paprika",
            "Sel et poivre noir, au goût",
            "Bâtonnets de céleri et trempette au fromage bleu, pour servir (facultatif)"
        ],
        "ingredient_liste": [
            "aile-de-poulet",
            "sauce-piquante",
            "beurre-fondu",
            "vinaigre-blanc",
            "poudre-d'ail",
            "paprika",
            "sel",
            "poivre-noir",
            "batonnets-de-celeri",
            "trempette-fromage-bleu"
        ],
        "preparation": [
            "Dans un grand bol, mélanger la sauce piquante, le beurre fondu, le vinaigre blanc, la poudre d'ail, le paprika, le sel et le poivre noir.",
            "Ajouter les ailes de poulet au mélange de sauce et bien enrober."
        ],
        "cuisson": [
            "Préchauffer le four à 200°C (400°F).",
            "Disposer les ailes de poulet en une seule couche sur une plaque de cuisson tapissée de papier sulfurisé.",
            "Cuire au four préchauffé pendant 45 à 50 minutes, en retournant les ailes à mi-cuisson, jusqu'à ce qu'elles soient cuites et croustillantes."],
        "cooking_methods": {
            "best": "four",
            "plancha": false,
            "barbecue": false,
            "grill": false
        },
        "mode_cuisson": ["four"],
        "accompagnement": "Les ailes de poulet Buffalo sont traditionnellement servies avec des bâtonnets de céleri croquants et une trempette au fromage bleu crémeuse.",
        "shortStory": "Les ailes de poulet Buffalo sont un plat emblématique de la cuisine américaine, apprécié pour son équilibre parfait entre épices et saveurs. Enrobées d'une sauce piquante au beurre, ces ailes de poulet cuites au four sont croustillantes à l'extérieur et tendres à l'intérieur, offrant une explosion de saveurs à chaque bouchée.",
        "detailURL": "/fiche-recette/15/ailes-poulet-buffalo-sauce-piquante-beurre/ailes-poulet-buffalo.html",
        "time_prepare": "10mn",
        "time_cooking": "50mn",
        "time_maceration": "0mn",
        "crispys": true
    },
    {
        "id": 16,
        "categorie": "asiatique",
        "country": "Thaïlande",
        "title": "Poulet au Curry Rouge Thaï",
        "subTitle": "Poulet tendre dans une sauce au curry épicée",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 blancs de poulet",
            "2 cuillères à soupe de pâte de curry rouge thaï",
            "1 boîte (400 ml) de lait de coco",
            "1 poivron rouge, coupé en lanières",
            "1 oignon, coupé en quartiers",
            "2 cuillères à soupe de sauce de poisson",
            "1 cuillère à soupe de sucre de palme ou de sucre brun",
            "Feuilles de basilic frais pour garnir",
            "Riz cuit pour servir"
        ],
        "ingredient_liste": [
            "blanc-de-poulet",
            "pate-de-curry-rouge-thai",
            "lait-de-coco",
            "poivron-rouge",
            "oignon",
            "sauce-de-poisson",
            "sucre-de-palme",
            "feuilles-de-basilic",
            "riz-cuit"
        ],
        "preparation": [
            "Dans une grande poêle, chauffer un peu d'huile à feu moyen.",
            "Ajouter la pâte de curry rouge et cuire pendant environ 1 minute pour libérer les arômes.",
            "Ajouter les blancs de poulet dans la poêle et faire revenir jusqu'à ce qu'ils soient dorés de chaque côté.",
            "Ajouter le lait de coco, le poivron rouge, l'oignon, la sauce de poisson et le sucre de palme dans la poêle. Bien mélanger pour combiner tous les ingrédients.",
            "Laisser mijoter à feu doux pendant environ 20-25 minutes, ou jusqu'à ce que le poulet soit cuit et que la sauce ait épaissi.",
            "Servir chaud sur du riz cuit et garnir de feuilles de basilic frais."
        ],
        "cuisson": [
            "Faire mijoter à feu doux pendant environ 20-25 minutes, ou jusqu'à ce que le poulet soit cuit et que la sauce ait épaissi."
        ],
        "cooking_methods": {
            "best": "poêle",
            "plancha": false,
            "barbecue": false,
            "grill": false
        },
        "mode_cuisson": ["poêle"],
        "accompagnement": "Ce poulet au curry rouge thaï est délicieux servi avec du riz cuit à la vapeur pour absorber la sauce épicée.",
        "shortStory": "Le poulet au curry rouge thaï est un plat classique de la cuisine thaïlandaise, apprécié pour sa saveur riche et épicée. Dans cette recette, des blancs de poulet tendres sont mijotés dans une sauce crémeuse au curry rouge, avec des poivrons rouges et des oignons pour une explosion de saveurs exotiques.",
        "detailURL": "/fiche-recette/16/poulet-curry-rouge-thai-blanc-lait-coco/poulet-curry-rouge-thai.html",
        "time_prepare": "15mn",
        "time_cooking": "25mn",
        "time_maceration": "0mn",
        "crispys": false
    },
    {
        "id": 17,
        "categorie": "europe",
        "country": "Italie",
        "title": "Poulet Parmesan à l'Italienne",
        "subTitle": "Escalopes de poulet panées et gratinées au parmesan",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 escalopes de poulet",
            "Sel et poivre, au goût",
            "100 g de chapelure",
            "50 g de parmesan râpé",
            "1 cuillère à café d’ail en poudre (optionnel)",
            "2 œufs battus",
            "200 g de mozzarella râpée (ou en tranches)",
            "400 ml de sauce tomate (ou sauce marinara)",
            "1 cuillère à café de basilic séché",
            "1 cuillère à soupe d’huile d'olive",
            "Persil frais ou basilic frais pour la garniture"
        ],
        "ingredient_liste": [
            "blanc-de-poulet",
            "chapelure",
            "parmesan-rape",
            "oeufs",
            "sauce-tomate",
            "mozzarella-rapee",
            "basilic-frais",
            "huile-d'olive",
            "sel",
            "poivre",
            "ail-en-poudre"
        ],
        "preparation": [
            "Préchauffer le four à 200°C (400°F).",
            "Si les escalopes de poulet sont épaisses, aplatissez-les légèrement pour qu’elles cuisent uniformément. Assaisonnez-les avec du sel et du poivre",
            "Dans un bol peu profond, mélanger la chapelure et le parmesan râpé, l'ail en poudre (si désiré). Assaisonner également avec du sel et du poivre selon votre goût.",
            "Tremper chaque escalope de poulet dans les œufs battus, puis les enrober du mélange de chapelure et de parmesan.",
            "Bien appuyer pour faire adhérer la panure"
        ],
        "cuisson": [
            "Chauffer un peu d'huile d'olive dans une grande poêle à feu moyen.",
            "Faites cuire les escalopes panées environ 3 minutes de chaque côté, jusqu'à ce qu’elles soient dorées (pas besoin de les cuire complètement, elles finiront de cuire au four)",
            "Transférer les escalopes dorées dans un plat de cuisson allant au four.",
            "Répartir la sauce tomate sur le dessus de chaque escalope, puis saupoudrer de mozzarella râpée.",
            "Enfournez pour 15 à 20 minutes, ou jusqu’à ce que le fromage soit fondu et doré, et que le poulet soit bien cuit.",
            "Servir chaud, garni de basilic frais haché."
        ],
        "cooking_methods": {
            "best": "poêle",
            "plancha": false,
            "barbecue": false,
            "grill": false
        },
        "mode_cuisson": ["poêle"],
        "accompagnement": "Servir chaud décoré avec du persil ou du basilic frais. Les escalopes de poulet Parmesan sont délicieuses servies avec des pâtes cuites al dente et une salade verte croquante ou du pain croustillant.",
        "shortStory": "Le poulet Parmesan, ou Pollo alla Parmigiana en italien, est un plat classique de la cuisine italienne, apprécié pour sa simplicité et ses saveurs réconfortantes. Dans cette recette, des escalopes de poulet panées sont gratinées avec de la sauce tomate, du fromage mozzarella et du parmesan râpé, pour une expérience gustative délicieusement italienne.",
        "detailURL": "/fiche-recette/17/poulet-parmesan-blanc-chapelure-parmesan-sauce-tomate/poulet-parmesan.html",
        "time_prepare": "15mn",
        "time_cooking": "20mn",
        "time_maceration": "0mn",
        "crispys": true
    },
    {
        "id": 19,
        "categorie": "asiatique",
        "country": "Vietnam",
        "title": "Poulet grillé au citron vert",
        "subTitle": "Saveurs fraîches et acidulées",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 cuisses de poulet",
            "4 gousses d'ail, émincées",
            "2 citrons verts, jus et zeste",
            "2 cuillères à soupe de sauce soja",
            "2 cuillères à soupe de miel",
            "1 cuillère à soupe d'huile d'olive",
            "1 cuillère à café de gingembre frais, râpé",
            "Sel et poivre, au goût"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "ail",
            "citrons-verts",
            "sauce-soja",
            "miel",
            "huile-d'olive",
            "gingembre-frais",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Dans un bol, mélanger l'ail émincé, le jus et le zeste des citrons verts, la sauce soja, le miel, l'huile d'olive, le gingembre râpé, le sel et le poivre pour préparer la marinade.",
            "Ajouter les cuisses de poulet dans le bol et bien les enrober de marinade.",
            "Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute la nuit."
        ],
        "cuisson": [
            "Préchauffer le barbecue à feu moyen-élevé.",
            "Retirer les cuisses de poulet de la marinade et les égoutter légèrement.",
            "Griller les cuisses de poulet sur le barbecue pendant environ 25 à 30 minutes, en les retournant de temps en temps, jusqu'à ce qu'elles soient bien cuites et légèrement caramélisées."],
        "cooking_methods": {
            "best": "barbecue",
            "plancha": false,
            "four": false,
            "grill": false
        },
        "mode_cuisson": ["barbecue"],
        "accompagnement": "Ce poulet grillé au citron vert est délicieux servi avec du riz basmati, des légumes grillés ou une salade fraîche.",
        "shortStory": "Le poulet grillé au citron vert est un plat vietnamien classique, apprécié pour ses saveurs fraîches et acidulées. Marinées dans une sauce à base de citron vert, de miel et de sauce soja, les cuisses de poulet sont grillées jusqu'à ce qu'elles soient tendres et juteuses, offrant une expérience gustative délicieusement parfumée.",
        "detailURL": "/fiche-recette/19/cuisses-poulet-citron-vert-grille.html",
        "time_prepare": "15mn",
        "time_maceration": "2h",
        "time_cooking": "30mn",

        "crispys": false
    }
    ,
    {
        "id": 20,
        "categorie": "amerique-nord",
        "country": "États-Unis",
        "title": "Ailes de poulet barbecue croustillantes",
        "subTitle": "Un classique américain",
        "chicken_piece": ["aile"],
        "piece": {
            "best": "aile",
            "entier": false,
            "aile": true,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "2 kg d'ailes de poulet",
            "2 tasses de sauce barbecue",
            "1/4 tasse de miel",
            "2 cuillères à soupe de vinaigre de cidre",
            "2 gousses d'ail, émincées",
            "1 cuillère à café de paprika",
            "Sel et poivre, au goût"
        ],
        "ingredient_liste": [
            "ailes-de-poulet",
            "sauce-barbecue",
            "miel",
            "vinaigre-de-cidre",
            "ail",
            "paprika",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Dans un grand bol, mélanger la sauce barbecue, le miel, le vinaigre de cidre, l'ail émincé, le paprika, le sel et le poivre pour préparer la marinade.",
            "Ajouter les ailes de poulet dans le bol et bien les enrober de marinade.",
            "Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute la nuit.",
        ],
        "cuisson": [
            "Préchauffer le barbecue à feu moyen-élevé. Retirer les ailes de poulet de la marinade et les égoutter légèrement.",
            "Griller les ailes de poulet sur le barbecue pendant environ 20 à 25 minutes, en les retournant et en les badigeonnant de marinade de temps en temps, jusqu'à ce qu'elles soient bien cuites et caramélisées.",
        ],
        "cooking_methods": {
            "best": "barbecue",
            "plancha": false,
            "four": false,
            "grill": false
        },
        "mode_cuisson": ["barbecue"],
        "accompagnement": "Les ailes de poulet barbecue sont délicieuses accompagnées de frites croustillantes et d'une sauce ranch maison.",
        "shortStory": "Les ailes de poulet barbecue sont un plat emblématique de la cuisine américaine, apprécié pour son mélange parfait de saveurs sucrées et épicées. Dans cette recette, les ailes de poulet sont marinées dans une sauce barbecue maison, puis grillées jusqu'à ce qu'elles soient dorées et croustillantes à l'extérieur, tendres et juteuses à l'intérieur.",
        "detailURL": "/fiche-recette/20/ailes-poulet-barbecue.html",
        "time_prepare": "15mn",
        "time_maceration": "2h",
        "time_cooking": "25mn",
        "crispys": true
    },
    {
        "id": 21,
        "categorie": "asiatique",
        "country": "Japon",
        "title": "Poulet Teriyaki",
        "subTitle": "Poulet glacé à la sauce teriyaki",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "blanc": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 filets de poulet",
            "1/2 tasse de sauce soja",
            "1/4 tasse de mirin",
            "2 cuillères à soupe de vinaigre de riz",
            "2 cuillères à soupe de miel",
            "2 gousses d'ail, émincées",
            "1 cuillère à café de gingembre frais râpé",
            "2 cuillères à soupe d'huile végétale",
            "Graines de sésame et oignons verts, pour garnir"
        ],
        "ingredient_liste": [
            "filets-de-poulet",
            "sauce-soja",
            "mirin",
            "vinaigre-de-riz",
            "miel",
            "ail",
            "gingembre-frais",
            "huile-vegetale",
            "graines-de-sesame",
            "oignons-verts"
        ],
        "preparation": [
            "Dans un bol, mélanger la sauce soja, le mirin, le vinaigre de riz, le miel, l'ail émincé et le gingembre râpé pour préparer la marinade.",
            "Ajouter les filets de poulet dans le bol et bien les enrober de marinade.",
            "Couvrir et laisser mariner au réfrigérateur pendant au moins 1 heure, de préférence toute la nuit."],
        "cuisson": [
            "Chauffer l'huile végétale dans une poêle à feu moyen-élevé.",
            "Retirer les filets de poulet de la marinade et les faire dorer des deux côtés dans la poêle chaude.",
            "Verser la marinade réservée dans la poêle et laisser mijoter jusqu'à ce que la sauce épaississe et enrobe le poulet, environ 5 minutes."
        ],
        "cooking_methods": {
            "best": "poêle",
            "plancha": false,
            "four": false,
            "grill": false
        },
        "mode_cuisson": ["poêle"],
        "accompagnement": "Le poulet teriyaki est délicieux servi avec du riz cuit à la vapeur, des nouilles soba ou des légumes sautés à l'ail et au gingembre.",
        "shortStory": "Le poulet teriyaki est un plat japonais populaire de poulet mariné et glacé dans une sauce teriyaki sucrée et salée. Dans cette recette, les filets de poulet sont marinés dans une sauce teriyaki maison, puis dorés à la poêle jusqu'à ce qu'ils soient tendres et juteux, avec une belle couleur caramélisée.",
        "detailURL": "/fiche-recette/21/poulet-teriyaki.html",
        "time_prepare": "15mn",
        "time_maceration": "1h",
        "time_cooking": "20mn",
        "crispys": false
    },
    {
        "id": 22,
        "categorie": "europe",
        "country": "France",
        "title": "Poulet Provençal Mijoté",
        "subTitle": "Poulet mijoté aux saveurs méditerranéennes",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 cuisses de poulet",
            "2 cuillères à soupe d'huile d'olive",
            "1 oignon, haché",
            "2 gousses d'ail, émincées",
            "2 poivrons rouges, coupés en lanières",
            "2 tomates, coupées en dés",
            "1 bouquet garni (thym, romarin, laurier)",
            "Sel et poivre, au goût"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "huile-d'olive",
            "oignon",
            "ail",
            "poivrons-rouges",
            "tomates",
            "bouquet-garni",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Dans une grande poêle ou une cocotte, chauffer l'huile d'olive à feu moyen.",
            "Ajouter les cuisses de poulet et les faire dorer de chaque côté, environ 5 minutes par côté. Retirer et réserver.",
            "Dans la même poêle, ajouter l'oignon haché et l'ail émincé. Faire revenir jusqu'à ce qu'ils soient tendres et dorés.",
            "Ajouter les poivrons rouges coupés en lanières et faire sauter pendant quelques minutes.",
            "Remettre les cuisses de poulet dans la poêle, ajouter les tomates coupées en dés et le bouquet garni. Assaisonner avec du sel et du poivre, au goût.",
            "Couvrir et laisser mijoter à feu doux pendant environ 25 à 30 minutes, ou jusqu'à ce que le poulet soit bien cuit et que les saveurs se soient bien mélangées.",
            "Servir le poulet à la provençale chaud, accompagné de riz, de pommes de terre sautées ou de légumes grillés."
        ],
        "cuisson": [
            "Faire dorer les cuisses de poulet dans une poêle.",
            "Mijoter avec les légumes jusqu'à ce que le poulet soit bien cuit et les saveurs se soient bien mélangées."
        ],
        "cooking_methods": {
            "best": "cocotte",
            "plancha": false,
            "four": false,
            "grill": false
        },
        "mode_cuisson": ["cocotte"],
        "accompagnement": "Le poulet à la provençale est délicieux accompagné d'un verre de vin rouge, de pain frais et d'une salade verte.",
        "shortStory": "Le poulet à la provençale est un plat français classique inspiré des saveurs méditerranéennes de la région de Provence. Ce plat réconfortant se compose de cuisses de poulet mijotées dans une sauce tomate aux herbes aromatiques, aux poivrons et aux oignons, offrant une explosion de saveurs en bouche.",
        "detailURL": "/fiche-recette/22/poulet-provencale.html",
        "time_prepare": "15mn",
        "time_maceration": "0mn",
        "time_cooking": "40mn",

        "crispys": false
    },
    {
        "id": 23,
        "categorie": "asiatique",
        "country": "Inde",
        "title": "Poulet Tandoori",
        "subTitle": "Poulet épicé cuit au four",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "blanc": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 blancs de poulet",
            "1 tasse de yaourt nature",
            "2 cuillères à soupe de jus de citron",
            "2 cuillères à soupe d'huile d'olive",
            "2 gousses d'ail, émincées",
            "1 cuillère à café de gingembre frais, râpé",
            "2 cuillères à soupe de pâte de tandoori",
            "1 cuillère à café de curcuma",
            "1 cuillère à café de paprika",
            "1 cuillère à café de cumin",
            "1 cuillère à café de coriandre moulue",
            "Sel et poivre, au goût",
            "Quelques tranches de citron, pour garnir",
            "Coriandre fraîche, pour garnir"
        ],
        "ingredient_liste": [
            "blancs-de-poulet",
            "yaourt-nature",
            "jus-de-citron",
            "huile-d'olive",
            "ail",
            "gingembre-frais",
            "pate-de-tandoori",
            "curcuma",
            "paprika",
            "cumin",
            "coriandre-moulue",
            "sel",
            "poivre",
            "tranches-de-citron",
            "coriandre-fraiche"
        ],
        "preparation": [
            "Dans un grand bol, mélanger le yaourt, le jus de citron, l'huile d'olive, l'ail, le gingembre, la pâte de tandoori, le curcuma, le paprika, le cumin, la coriandre moulue, le sel et le poivre.",
            "Ajouter les blancs de poulet dans le mélange et bien les enrober de marinade. Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute la nuit.",
            "Préchauffer le four à 200°C.",
            "Transférer les blancs de poulet marinés dans un plat allant au four et cuire au four préchauffé pendant environ 25 à 30 minutes, ou jusqu'à ce que le poulet soit bien cuit et légèrement doré.",
            "Servir le poulet tandoori chaud, garni de tranches de citron et de feuilles de coriandre fraîche."
        ],
        "cuisson": [
            "Cuire les blancs de poulet marinés au four préchauffé."
        ],
        "cooking_methods": {
            "best": "four",
            "plancha": false,
            "four": true,
            "grill": false
        },
        "mode_cuisson": ["four"],
        "accompagnement": "Le poulet tandoori est délicieux servi avec du riz basmati, du pain naan chaud et une sauce au yaourt et à la menthe.",
        "shortStory": "Le poulet tandoori est un plat emblématique de la cuisine indienne, caractérisé par ses épices aromatiques et sa couleur rouge vif. Ce poulet mariné est cuit au four, ce qui lui confère une texture tendre et juteuse avec une saveur épicée et délicieuse.",
        "detailURL": "/fiche-recette/23/poulet-tandoori.html",
        "time_prepare": "15mn",
        "time_maceration": "2h",
        "time_cooking": "30mn",

        "crispys": false
    }
    ,
    {
        "id": 24,
        "categorie": "asiatique",
        "country": "Thaïlande",
        "title": "Poulet au Curry Vert",
        "subTitle": "Curry thaïlandais épicé",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 cuisses de poulet désossées et sans peau",
            "2 cuillères à soupe d'huile végétale",
            "4 cuillères à soupe de pâte de curry vert thaïlandais",
            "1 boîte de lait de coco (400 ml)",
            "1 poivron rouge, coupé en lanières",
            "1 aubergine, coupée en dés",
            "1 courgette, coupée en rondelles",
            "1 carotte, coupée en rondelles",
            "1 oignon, émincé",
            "2 gousses d'ail, émincées",
            "1 cuillère à soupe de sauce de poisson",
            "1 cuillère à soupe de sucre de palme ou de sucre de canne",
            "Feuilles de basilic thaïlandais, pour garnir"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "huile-vegetale",
            "pate-de-curry-vert",
            "lait-de-coco",
            "poivron-rouge",
            "aubergine",
            "courgette",
            "carotte",
            "oignon",
            "ail",
            "sauce-poisson",
            "sucre-palme",
            "feuilles-basilic-thailandais"
        ],
        "preparation": [
            "Dans une grande poêle ou un wok, chauffer l'huile végétale à feu moyen.",
            "Ajouter la pâte de curry vert et cuire pendant environ 1 à 2 minutes, en remuant, jusqu'à ce qu'elle devienne aromatique.",
            "Ajouter les cuisses de poulet dans la poêle et cuire pendant environ 5 minutes de chaque côté, jusqu'à ce qu'elles soient dorées.",
            "Ajouter l'oignon et l'ail dans la poêle et cuire pendant 2 à 3 minutes, jusqu'à ce qu'ils soient ramollis.",
            "Incorporer le lait de coco, la sauce de poisson et le sucre de palme dans la poêle. Porter à ébullition, puis réduire le feu et laisser mijoter pendant environ 10 minutes.",
            "Ajouter le poivron rouge, l'aubergine, la courgette et la carotte dans la poêle. Couvrir et laisser mijoter pendant encore 10 minutes, ou jusqu'à ce que les légumes soient tendres et le poulet soit bien cuit.",
            "Servir le poulet au curry vert chaud, garni de feuilles de basilic thaïlandais, avec du riz parfumé."
        ],
        "cuisson": [
            "Cuire les cuisses de poulet dans une poêle jusqu'à ce qu'elles soient dorées, puis mijoter avec les légumes dans une sauce au curry vert."
        ],
        "cooking_methods": {
            "best": "poêle",
            "plancha": false,
            "four": false,
            "grill": false
        },
        "mode_cuisson": ["poêle"],
        "accompagnement": "Le poulet au curry vert thaïlandais est traditionnellement servi avec du riz jasmin cuit à la vapeur, mais vous pouvez également le déguster avec des nouilles de riz ou des naans.",
        "shortStory": "Le poulet au curry vert est un plat thaïlandais classique, apprécié pour sa saveur épicée et aromatique. Dans cette recette, les cuisses de poulet sont mijotées dans une sauce onctueuse au curry vert, avec un mélange de légumes colorés pour un plat délicieusement parfumé et réconfortant.",
        "detailURL": "/fiche-recette/24/poulet-au-curry-vert-thailandais/poulet-au-curry-vert.html",
        "time_prepare": "20mn",
        "time_maceration": "0mn",
        "time_cooking": "30mn",
        "crispys": false
    },
    {
        "id": 25,
        "categorie": "moyen-orient",
        "country": "Liban",
        "title": "Chich Taouk",
        "subTitle": "Brochettes de poulet libanaises",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "pilon": false,
            "manchon": false,
            "blanc": true,
            "cuisse": false
        },
        "ingredient": [
            "600g de blancs de poulet, coupés en cubes",
            "1/4 tasse de yaourt grec",
            "3 cuillères à soupe de jus de citron",
            "2 cuillères à soupe d'huile d'olive",
            "2 gousses d'ail émincées",
            "1 cuillère à café de paprika",
            "1 cuillère à café de cumin",
            "1/2 cuillère à café de curcuma",
            "1/4 cuillère à café de cannelle",
            "Sel et poivre, au goût",
            "Brochettes en bois (trempées dans l'eau)"
        ],
        "ingredient_liste": [
            "blancs-de-poulet",
            "yaourt-grec",
            "jus-de-citron",
            "huile-d'olive",
            "ail",
            "paprika",
            "cumin",
            "curcuma",
            "cannelle",
            "sel",
            "poivre",
            "brochettes-en-bois"
        ],
        "preparation": [
            "Dans un bol, mélanger le yaourt grec, le jus de citron, l'huile d'olive, l'ail émincé, le paprika, le cumin, le curcuma, la cannelle, le sel et le poivre pour préparer la marinade.",
            "Ajouter les cubes de blancs de poulet dans la marinade et bien mélanger pour les enrober. Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute la nuit.",
            "Enfiler les cubes de poulet marinés sur les brochettes en bois préalablement trempées dans l'eau.",
            "Faire griller les brochettes de poulet sur un barbecue ou sous le gril du four pendant environ 10 à 15 minutes de chaque côté, ou jusqu'à ce qu'elles soient bien cuits et légèrement dorés.",
            "Servir les chich taouk chauds avec du riz pilaf, des légumes grillés et une sauce à l'ail et au citron."
        ],
        "cuisson": [
            "Faire griller les brochettes de poulet sur un barbecue ou sous le gril du four pendant environ 10 à 15 minutes de chaque côté."
        ],
        "cooking_methods": {
            "best": "barbecue",
            "plancha": false,
            "four": false,
            "grill": false
        },
        "mode_cuisson": ["barbecue"],
        "accompagnement": "Les chich taouk sont délicieux accompagnés de riz pilaf, de légumes grillés, de pain pita chaud et de sauce à l'ail et au citron.",
        "shortStory": "Les chich taouk sont des brochettes de poulet libanaises marinées dans un mélange d'herbes et d'épices, puis grillées pour une saveur délicieusement parfumée. Cette recette traditionnelle est populaire dans toute la région du Moyen-Orient et est souvent servie lors de fêtes et de rassemblements familiaux.",
        "detailURL": "/fiche-recette/25/blancs-poulet-yaourt-citron-chich-taouk.html",
        "time_prepare": "15mn",
        "time_maceration": "2h",
        "time_cooking": "20mn",

        "crispys": false
    }, {
        "id": 26,
        "categorie": "europe",
        "country": "France",
        "title": "Poulet Méditerranéen au Four",
        "subTitle": "Plat traditionnel français",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 cuisses de poulet avec peau",
            "2 tomates, coupées en dés",
            "1 oignon, émincé",
            "2 gousses d'ail, émincées",
            "1 poivron rouge, coupé en lanières",
            "1 poivron jaune, coupé en lanières",
            "2 cuillères à soupe d'huile d'olive",
            "1 cuillère à café d'herbes de Provence",
            "Sel et poivre, au goût",
            "Quelques feuilles de basilic frais, pour garnir"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "tomates",
            "oignon",
            "ail",
            "poivron-rouge",
            "poivron-jaune",
            "huile-d'olive",
            "herbes-de-provence",
            "sel",
            "poivre",
            "feuilles-de-basilic-frais"
        ],
        "preparation": [
            "Préchauffer le four à 180°C (350°F).",
            "Dans une grande poêle allant au four, chauffer l'huile d'olive à feu moyen.",
            "Ajouter les cuisses de poulet dans la poêle et les faire dorer de chaque côté pendant environ 5 minutes.",
            "Retirer le poulet de la poêle et réserver.",
            "Dans la même poêle, ajouter l'oignon et l'ail, et faire revenir pendant 2 minutes.",
            "Ajouter les poivrons coupés en lanières dans la poêle et faire sauter pendant environ 5 minutes, jusqu'à ce qu'ils soient tendres.",
            "Remettre les cuisses de poulet dans la poêle, puis ajouter les dés de tomates et les herbes de Provence. Assaisonner avec du sel et du poivre selon votre goût.",
            "Transférer la poêle au four préchauffé et cuire pendant environ 25 à 30 minutes, ou jusqu'à ce que le poulet soit bien cuit et que les légumes soient légèrement caramélisés.",
            "Servir le poulet à la provençale chaud, garni de feuilles de basilic frais."
        ],
        "cuisson": [
            "Faire dorer les cuisses de poulet dans une poêle, puis les cuire au four avec les légumes et les herbes de Provence."
        ],
        "cooking_methods": {
            "best": "four",
            "plancha": false,
            "four": true,
            "grill": false
        },
        "mode_cuisson": ["four"],
        "accompagnement": "Le poulet à la provençale est délicieux servi avec des pommes de terre rôties, des pâtes fraîches ou du riz basmati.",
        "shortStory": "Le poulet à la provençale est un plat traditionnel français, originaire de la région de Provence. Il est préparé avec des cuisses de poulet dorées, des légumes frais comme les poivrons et les tomates, et parfumé avec des herbes de Provence pour une saveur méditerranéenne authentique.",
        "detailURL": "/fiche-recette/26/poulet-a-la-provencale/poulet-a-la-provencale.html",
        "time_prepare": "15mn",
        "time_maceration": "0mn",
        "time_cooking": "40mn",
        "crispys": false
    },
    {
        "id": 27,
        "categorie": "amerique-nord",
        "country": "États-Unis",
        "title": "Poulet au Barbecue tendre",
        "subTitle": "Ailes de poulet grillées sauce barbecue",
        "chicken_piece": ["aile"],
        "piece": {
            "best": "aile",
            "entier": false,
            "aile": true,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "1 kg d'ailes de poulet",
            "1 tasse de sauce barbecue",
            "2 cuillères à soupe de miel",
            "2 cuillères à soupe de vinaigre de cidre",
            "1 cuillère à café de paprika",
            "1 cuillère à café de poudre d'ail",
            "Sel et poivre, au goût"
        ],
        "ingredient_liste": [
            "ailes-de-poulet",
            "sauce-barbecue",
            "miel",
            "vinaigre-de-cidre",
            "paprika",
            "ail",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Préchauffer le barbecue à feu moyen.",
            "Dans un bol, mélanger la sauce barbecue, le miel, le vinaigre de cidre, le paprika, la poudre d'ail, le sel et le poivre pour préparer la marinade.",
            "Ajouter les ailes de poulet dans le bol et bien les enrober de marinade. Laisser mariner au réfrigérateur pendant au moins 1 heure.",

        ],
        "cuisson": [
            "Griller les ailes de poulet marinées sur le barbecue préchauffé pendant environ 20 à 25 minutes, en les retournant régulièrement et en les badigeonnant de marinade supplémentaire.",
            "Servir chaud et déguster avec des crudités ou des frites."
        ],
        "cooking_methods": {
            "best": "barbecue",
            "plancha": false,
            "four": false,
            "grill": false
        },
        "mode_cuisson": ["barbecue"],
        "accompagnement": "Ces ailes de poulet au barbecue sont délicieuses servies avec une salade fraîche, des légumes grillés ou des frites croustillantes.",
        "shortStory": "Le poulet au barbecue est un classique américain apprécié pour sa saveur fumée et sa tendreté. Cette recette d'ailes de poulet marinées dans une sauce barbecue sucrée et épicée est parfaite pour un barbecue en plein air entre amis ou en famille.",
        "detailURL": "/fiche-recette/27/ailes-poulet-barbecue-sauce-barbecue.html",
        "time_prepare": "15mn",
        "time_maceration": "1h",
        "time_cooking": "25mn",
        "crispys": false
    }, {
        "id": 29,
        "categorie": "europe",
        "country": "France",
        "title": "Poulet à la moutarde et au romarin",
        "subTitle": "Poulet rôti à la moutarde de Dijon",
        "chicken_piece": ["blanc", "cuisse"],
        "piece": {
            "best": "blanc",
            "blanc": true,
            "cuisse": true,
            "aile": false,
            "pilon": false
        },
        "ingredient": [
            "4 blancs de poulet",
            "2 cuillères à soupe de moutarde de Dijon",
            "2 cuillères à soupe de miel",
            "2 branches de romarin frais",
            "3 gousses d'ail émincées",
            "1 cuillère à soupe d'huile d'olive",
            "Pincée de sel et poivre"
        ],
        "ingredient_liste": [
            "blancs-de-poulet",
            "moutarde-de-dijon",
            "miel",
            "romarin",
            "ail-eminces",
            "huile-d'olive",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Dans un bol, mélanger la moutarde, le miel, l'ail, l'huile d'olive, le sel et le poivre pour faire la marinade.",
            "Placer les blancs de poulet dans la marinade, bien enrober. Ajouter les branches de romarin. Couvrir et laisser mariner pendant 1 heure.",
            "Sortir le poulet de la marinade avant la cuisson."
        ],
        "cuisson": [
            "Préchauffer le four à 180°C. Disposer les blancs de poulet dans un plat, arroser avec la marinade restante et enfourner pendant 25 à 30 minutes.",
            "Servir chaud avec des pommes de terre rôties et des légumes de saison."
        ],
        "cooking_methods": {
            "best": "four",
            "plancha": false,
            "four": true,
            "friture": false
        },
        "mode_cuisson": ["four"],
        "accompagnement": "Accompagner ce poulet de pommes de terre rôties, de légumes de saison ou d'une salade pour un repas savoureux.",
        "shortStory": "Le poulet à la moutarde est une recette traditionnelle française, célèbre pour sa simplicité et son goût exquis. L'association de la moutarde, du miel et du romarin apporte une touche à la fois rustique et raffinée à ce plat.",
        "detailURL": "/fiche-recette/3/poulet-moutarde-romarin/poulet-moutarde-romarin.html",
        "time_prepare": "15mn",
        "time_maceration": "1h",
        "time_cooking": "30mn",
        "crispys": false
    },
    {
        "id": 30,
        "categorie": "asiatique",
        "country": "Chine",
        "title": "Poulet sauce soja et gingembre",
        "subTitle": "Poulet mariné au gingembre et à la sauce soja",
        "chicken_piece": ["cuisse", "pilon"],
        "piece": {
            "best": "cuisse",
            "blanc": false,
            "cuisse": true,
            "aile": false,
            "pilon": true
        },
        "ingredient": [
            "4 cuisses de poulet",
            "3 cuillères à soupe de sauce soja",
            "2 cuillères à soupe de miel",
            "1 morceau de gingembre frais râpé",
            "2 gousses d'ail émincées",
            "1 cuillère à soupe d'huile de sésame",
            "1 cuillère à soupe de vinaigre de riz",
            "Pincée de poivre",
            "Graines de sésame (pour garnir)"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "sauce-soja",
            "miel",
            "gingembre-rape",
            "ail-eminces",
            "huile-de-sesame",
            "vinaigre-de-riz",
            "poivre",
            "graines-de-sesame"
        ],
        "preparation": [
            "Dans un bol, mélanger la sauce soja, le miel, le gingembre râpé, l'ail, l'huile de sésame, le vinaigre de riz et le poivre pour faire la marinade.",
            "Placer les cuisses et pilons de poulet dans la marinade, bien enrober. Couvrir et laisser mariner au réfrigérateur pendant 2 à 4 heures.",
            "Sortir le poulet de la marinade et égoutter légèrement avant de cuire."
        ],
        "cuisson": [
            "Préchauffer le four à 200°C. Cuire les cuisses de poulet dans un plat allant au four pendant 35 à 40 minutes, en les arrosant régulièrement avec la marinade.",
            "Servir chaud, garni de graines de sésame et accompagné de légumes sautés ou de riz."
        ],
        "cooking_methods": {
            "best": "four",
            "plancha": false,
            "four": true,
            "friture": false
        },
        "mode_cuisson": ["four"],
        "accompagnement": "Servir avec des légumes sautés, du riz ou des nouilles pour un repas équilibré et délicieux.",
        "shortStory": "Cette recette de poulet mariné au gingembre et à la sauce soja est inspirée de la cuisine chinoise. Elle combine des saveurs sucrées, salées et épicées pour un plat réconfortant et savoureux. Parfait pour un dîner facile et gourmand.",
        "detailURL": "/fiche-recette/2/poulet-sauce-soja-gingembre/poulet-sauce-soja-gingembre.html",
        "time_prepare": "20mn",
        "time_maceration": "2h",
        "time_cooking": "40mn",
        "crispys": false
    },
    {
        "id": 44,
        "categorie": "afrique",
        "country": "Afrique",
        "title": "Poulet aux épices africaines",
        "subTitle": "Poulet rôti aux saveurs épicées",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 cuisses de poulet",
            "2 cuillères à soupe de mélange d'épices africaines",
            "1 oignon",
            "4 gousses d'ail",
            "1 citron",
            "huile d'olive",
            "sel",
            "poivre"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "epices-africaines",
            "oignon",
            "ail",
            "citron",
            "huile-d-olive",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Préchauffer le four à 200°C.",
            "Mélanger les épices, l'huile d'olive, le sel et le poivre.",
            "Enrober les cuisses de poulet avec la marinade.",
            "Ajouter l'oignon et l'ail dans le plat.",
            "Cuire au four pendant 40 minutes."
        ],
        "cuisson": [
            "Cuire au four jusqu'à ce que le poulet soit bien doré."
        ],
        "cooking_methods": {
            "best": "four",
            "plancha": false,
            "barbecue": false,
            "grill": false
        },
        "mode_cuisson": ["four"],
        "accompagnement": "Servir avec du riz ou des légumes grillés.",
        "shortStory": "Ce plat est un hommage aux saveurs vibrantes de l'Afrique.",
        "detailURL": "/fiche-recette/30/poulet-epices-africaines/poulet-epices-africaines.html",
        "time_prepare": "15mn",
        "time_maceration": "1h",
        "time_cooking": "40mn",
        "crispys": false
    }
    , {
        "id": 31,
        "categorie": "amerique-sud",
        "country": "Pérou",
        "title": "Poulet à la Chicha",
        "subTitle": "Poulet mariné à la bière",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "blanc": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 blancs de poulet",
            "1 tasse de chicha",
            "2 cuillères à soupe de cumin",
            "2 gousses d'ail",
            "1 citron vert",
            "sel",
            "poivre"
        ],
        "ingredient_liste": [
            "blancs-de-poulet",
            "chicha",
            "cumin",
            "ail",
            "citron-vert",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Mélanger la chicha, le cumin, l'ail, le sel et le poivre.",
            "Mariner les blancs de poulet pendant 2 heures.",
            "Préchauffer le grill et cuire les blancs de poulet jusqu'à ce qu'ils soient dorés."
        ],
        "cuisson": [
            "Cuire sur le grill jusqu'à ce que le poulet soit bien cuit."
        ],
        "cooking_methods": {
            "best": "grill",
            "plancha": false,
            "barbecue": false,
            "friture": false
        },
        "mode_cuisson": ["grill"],
        "accompagnement": "Servir avec une salade fraîche.",
        "shortStory": "Un plat traditionnel péruvien, plein de saveurs locales.",
        "detailURL": "/fiche-recette/31/poulet-chicha/poulet-chicha.html",
        "time_prepare": "10mn",
        "time_maceration": "2h",
        "time_cooking": "20mn",
        "crispys": false
    }
    , {
        "id": 32,
        "categorie": "amerique-nord",
        "country": "États-Unis",
        "title": "Poulet Buffalo au four",
        "subTitle": "Ailes de poulet à la sauce piquante",
        "chicken_piece": ["aile"],
        "piece": {
            "best": "aile",
            "entier": false,
            "aile": true,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "1 kg d'ailes de poulet",
            "1/2 tasse de sauce Buffalo",
            "1 cuillère à soupe de beurre",
            "1 cuillère à café de paprika"
        ],
        "ingredient_liste": [
            "ailes-de-poulet",
            "sauce-buffalo",
            "beurre",
            "paprika"
        ],
        "preparation": [
            "Préchauffer le four à 220°C.",
            "Mélanger les ailes de poulet avec la sauce Buffalo, le beurre et le paprika.",
            "Disposer les ailes sur une plaque et cuire au four pendant 30 minutes."
        ],
        "cuisson": [
            "Cuire au four jusqu'à ce qu'elles soient croustillantes."
        ],
        "cooking_methods": {
            "best": "four",
            "plancha": false,
            "barbecue": false,
            "friture": false
        },
        "mode_cuisson": ["four"],
        "accompagnement": "Servir avec du céleri et de la sauce ranch.",
        "shortStory": "Un plat américain classique, parfait pour les soirées.",
        "detailURL": "/fiche-recette/32/poulet-buffalo/poulet-buffalo.html",
        "time_prepare": "10mn",
        "time_cooking": "30mn",
        "time_maceration":"0mn",
        "crispys": true
    }
    , {
        "id": 33,
        "categorie": "amerique-nord",
        "country": "États-Unis",
        "title": "Poulet Cajun",
        "subTitle": "Poulet épicé à la mode Cajun",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 cuisses de poulet",
            "2 cuillères à soupe d'épices Cajun",
            "1 oignon",
            "2 poivrons",
            "1/4 tasse d'huile d'olive"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "epices-cajun",
            "oignon",
            "poivrons",
            "huile-d-olive"
        ],
        "preparation": [
            "Assaisonner les cuisses de poulet avec les épices Cajun.",
            "Dans une poêle, faire chauffer l'huile et cuire les cuisses jusqu'à ce qu'elles soient dorées.",
            "Ajouter l'oignon et les poivrons, cuire jusqu'à tendreté."
        ],
        "cuisson": [
            "Cuire jusqu'à ce que le poulet soit bien cuit."
        ],
        "cooking_methods": {
            "best": "poêle",
            "plancha": false,
            "barbecue": false,
            "friture": false
        },
        "mode_cuisson": ["poêle"],
        "accompagnement": "Servir avec du riz ou des haricots.",
        "shortStory": "Un plat plein de saveurs du sud des États-Unis.",
        "detailURL": "/fiche-recette/33/poulet-cajun/poulet-cajun.html",
        "time_prepare": "15mn",
        "time_cooking": "25mn",
        "time_maceration":"0mn",
        "crispys": false
    }
    , {
        "id": 34,
        "categorie": "amerique-nord",
        "country": "États-Unis",
        "title": "Poulet BBQ à l'ananas",
        "subTitle": "Poulet mariné à l'ananas grillé",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "blanc": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 blancs de poulet",
            "1 tasse de jus d'ananas",
            "1/4 tasse de sauce soja",
            "2 cuillères à soupe de miel"
        ],
        "ingredient_liste": [
            "blancs-de-poulet",
            "jus-d-ananas",
            "sauce-soja",
            "miel"
        ],
        "preparation": [
            "Mélanger le jus d'ananas, la sauce soja et le miel.",
            "Mariner les blancs de poulet pendant 1 heure.",
            "Préchauffer le barbecue et cuire les blancs jusqu'à ce qu'ils soient cuits."
        ],
        "cuisson": [
            "Cuire sur le barbecue jusqu'à ce qu'ils soient bien cuits."
        ],
        "cooking_methods": {
            "best": "barbecue",
            "plancha": false,
            "friture": false,
            "four": false
        },
        "mode_cuisson": ["barbecue"],
        "accompagnement": "Servir avec une salade de chou.",
        "shortStory": "Un mélange parfait entre le sucré et le salé.",
        "detailURL": "/fiche-recette/34/poulet-bbq-ananas/poulet-bbq-ananas.html",
        "time_prepare": "10mn",
        "time_maceration": "1h",
        "time_cooking": "20mn",
        "crispys": false
    },
    {
        "id": 35,
        "categorie": "asiatique",
        "country": "Chine",
        "title": "Poulet au citron et au gingembre",
        "subTitle": "Poulet mariné au gingembre frais",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "blanc": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 blancs de poulet",
            "2 cuillères à soupe de gingembre râpé",
            "1 citron",
            "2 cuillères à soupe de sauce soja",
            "1 gousse d'ail"
        ],
        "ingredient_liste": [
            "blancs-de-poulet",
            "gingembre",
            "citron",
            "sauce-soja",
            "ail"
        ],
        "preparation": [
            "Mélanger le gingembre, le jus de citron, la sauce soja et l'ail.",
            "Mariner les blancs de poulet pendant 30 minutes.",
            "Cuire sur la plancha jusqu'à ce qu'ils soient dorés."
        ],
        "cuisson": [
            "Cuire sur la plancha jusqu'à ce que le poulet soit bien cuit."
        ],
        "cooking_methods": {
            "best": "plancha",
            "barbecue": false,
            "friture": false,
            "four": false
        },
        "mode_cuisson": ["plancha"],
        "accompagnement": "Servir avec des légumes sautés.",
        "shortStory": "Une recette rafraîchissante et pleine de saveurs.",
        "detailURL": "/fiche-recette/35/poulet-citron-gingembre/poulet-citron-gingembre.html",
        "time_prepare": "10mn",
        "time_maceration": "30mn",
        "time_cooking": "10mn",
        "crispys": false
    },
    {
        "id": 36,
        "categorie": "asiatique",
        "country": "Japon",
        "title": "Poulet au Sésame",
        "subTitle": "Poulet frit au sésame croquant",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "blanc": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 filets de poulet",
            "1/2 tasse de graines de sésame",
            "2 œufs",
            "1 tasse de farine"
        ],
        "ingredient_liste": [
            "filets-de-poulet",
            "graines-de-sesame",
            "oeufs",
            "farine"
        ],
        "preparation": [
            "Tremper les filets de poulet dans les œufs battus, puis les enrober de farine et de graines de sésame.",
            "Faire chauffer l'huile dans une poêle et cuire les filets jusqu'à ce qu'ils soient dorés."
        ],
        "cuisson": [
            "Cuire à la friture jusqu'à ce qu'ils soient croustillants."
        ],
        "cooking_methods": {
            "best": "friture",
            "plancha": false,
            "barbecue": false,
            "four": false
        },
        "mode_cuisson": ["friture"],
        "accompagnement": "Servir avec du riz et des légumes.",
        "shortStory": "Un plat savoureux et croustillant, idéal pour les amateurs de sésame.",
        "detailURL": "/fiche-recette/36/poulet-sesame/poulet-sesame.html",
        "time_prepare": "15mn",
        "time_cooking": "10mn",
        "time_maceration": "0mn",
        "crispys": true
    }
    , {
        "id": 37,
        "categorie": "asiatique",
        "country": "Japon",
        "title": "Poulet Katsu",
        "subTitle": "Escalopes de poulet panées à la japonaise",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "blanc": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 blancs de poulet",
            "1 tasse de panko",
            "2 œufs",
            "1/2 tasse de farine"
        ],
        "ingredient_liste": [
            "blancs-de-poulet",
            "panko",
            "oeufs",
            "farine"
        ],
        "preparation": [
            "Tremper les blancs de poulet dans la farine, les œufs et les enrober de panko.",
            "Faire chauffer l'huile dans une poêle et cuire jusqu'à ce qu'ils soient dorés."
        ],
        "cuisson": [
            "Cuire à la friture jusqu'à ce qu'ils soient croustillants."
        ],
        "cooking_methods": {
            "best": "friture",
            "plancha": false,
            "barbecue": false,
            "four": false
        },
        "mode_cuisson": ["friture"],
        "accompagnement": "Servir avec du riz et de la sauce tonkatsu.",
        "shortStory": "Un plat japonais classique, croustillant et délicieux.",
        "detailURL": "/fiche-recette/37/poulet-katsu/poulet-katsu.html",
        "time_prepare": "15mn",
        "time_cooking": "10mn",
        "time_maceration": "0mn",
        "crispys": true
    }
    , {
        "id": 38,
        "categorie": "europe",
        "country": "France",
        "title": "Poulet à la Provençale",
        "subTitle": "Poulet mijoté aux herbes de Provence",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 cuisses de poulet",
            "2 tomates",
            "1 oignon",
            "1 gousse d'ail",
            "herbes de Provence",
            "huile d'olive"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "tomates",
            "oignon",
            "ail",
            "herbes-de-provence",
            "huile-d-olive"
        ],
        "preparation": [
            "Dans une cocotte, faire revenir l'oignon et l'ail dans l'huile d'olive.",
            "Ajouter les cuisses de poulet et faire dorer.",
            "Ajouter les tomates et les herbes, couvrir et mijoter pendant 40 minutes."
        ],
        "cuisson": [
            "Mijoter jusqu'à ce que le poulet soit tendre."
        ],
        "cooking_methods": {
            "best": "cocotte",
            "plancha": false,
            "barbecue": false,
            "four": false
        },
        "mode_cuisson": ["cocotte"],
        "accompagnement": "Servir avec des pâtes ou du pain.",
        "shortStory": "Un plat classique français aux saveurs méditerranéennes.",
        "detailURL": "/fiche-recette/38/poulet-provencal/poulet-provencal.html",
        "time_prepare": "15mn",
        "time_cooking": "40mn",
        "time_maceration": "0mn",
        "crispys": false
    },
    {
        "id": 39,
        "categorie": "asiatique",
        "country": "Thaïlande",
        "title": "Poulet au curry vert",
        "subTitle": "Poulet tendre dans une sauce au curry vert",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "blanc": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 blancs de poulet",
            "1 boîte de lait de coco",
            "2 cuillères à soupe de pâte de curry vert",
            "1 poivron",
            "basilic",
            "huile"
        ],
        "ingredient_liste": [
            "blancs-de-poulet",
            "lait-de-coco",
            "pate-de-curry-vert",
            "poivron",
            "basilic",
            "huile"
        ],
        "preparation": [
            "Dans une poêle, faire revenir le poulet dans l'huile.",
            "Ajouter la pâte de curry et le lait de coco.",
            "Ajouter le poivron et le basilic, laisser mijoter jusqu'à cuisson complète."
        ],
        "cuisson": [
            "Mijoter jusqu'à ce que le poulet soit cuit."
        ],
        "cooking_methods": {
            "best": "cocotte",
            "plancha": false,
            "barbecue": false,
            "four": false
        },
        "mode_cuisson": ["cocotte"],
        "accompagnement": "Servir avec du riz basmati.",
        "shortStory": "Un plat thaïlandais savoureux et parfumé.",
        "detailURL": "/fiche-recette/39/poulet-curry-vert/poulet-curry-vert.html",
        "time_prepare": "10mn",
        "time_cooking": "30mn",
        "time_maceration": "0mn",
        "crispys": false
    },
    {
        "id": 45,
        "categorie": "afrique",
        "country": "Maroc",
        "title": "Poulet à la Marocaine",
        "subTitle": "Poulet aux saveurs orientales",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 cuisses de poulet",
            "1 oignon",
            "2 cuillères à soupe de ras el hanout",
            "200g de pois chiches",
            "1 citron confit"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "oignon",
            "ras-el-hanout",
            "pois-chiches",
            "citron-confit"
        ],
        "preparation": [
            "Faire revenir l'oignon dans une cocotte.",
            "Ajouter les cuisses de poulet et les épices.",
            "Incorporer les pois chiches et le citron confit.",
            "Mouiller avec de l'eau et mijoter jusqu'à cuisson complète."
        ],
        "cuisson": [
            "Faire revenir l'oignon dans une cocotte. Ajouter les cuisses de poulet et les épices. Incorporer les pois chiches et le citron confit. Mouiller avec de l'eau et mijoter jusqu'à cuisson complète."
        ],
        "cooking_methods": {
            "best": "cocotte",
            "plancha": false,
            "friture": false,
            "barbecue": false
        },
        "mode_cuisson": ["cocotte"],
        "accompagnement": "Servir avec du couscous.",
        "detailURL": "/fiche-recette/45/poulet-marocain/poulet-marocain.html",
        "time_prepare": "15mn",
        "time_cooking": "45mn",
        "shortStory": "Le poulet à la marocaine est un plat riche en saveurs, mettant en avant des épices traditionnelles et des ingrédients frais, créant un mélange parfait qui évoque la cuisine marocaine.",
        "time_maceration": "0mn",  // Peut être omis si non applicable
        "crispys": false
    },
    {
        "id": 46,
        "categorie": "europe",
        "country": "France",
        "title": "Poulet aux Amandes",
        "subTitle": "Un poulet tendre avec une croûte d'amandes",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "blanc": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 blancs de poulet",
            "100g d'amandes effilées",
            "2 œufs",
            "2 cuillères à soupe de farine",
            "Sel et poivre"
        ],
        "ingredient_liste": [
            "blancs-de-poulet",
            "amandes-effilees",
            "oeufs",
            "farine",
            "sel-et-poivre"
        ],
        "preparation": [
            "Battre les œufs et assaisonner.",
            "Tremper les blancs de poulet dans la farine, puis dans les œufs et enfin les amandes.",
            "Faire cuire à la poêle jusqu'à ce qu'ils soient dorés des deux côtés."
        ],
        "cuisson": [
            "Faire cuire à la poêle jusqu'à ce qu'ils soient dorés des deux côtés."
        ],
        "cooking_methods": {
            "best": "poêle",
            "plancha": false,
            "friture": false,
            "barbecue": false
        },
        "mode_cuisson": ["poêle"],
        "accompagnement": "Servir avec une salade verte.",
        "detailURL": "/fiche-recette/46/poulet-amandes/poulet-amandes.html",
        "time_prepare": "10mn",
        "time_cooking": "20mn",
        "shortStory": "Le poulet aux amandes est un plat classique français, apprécié pour sa texture croustillante et son goût délicat.",
        "time_maceration": "0mn",  // Peut être omis si non applicable
        "crispys": false
    }
    ,
    {
        "id": 48,
        "categorie": "moyen-orient",
        "country": "Liban",
        "title": "Poulet au Tahini",
        "subTitle": "Poulet tendre nappé d'une sauce au tahini",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "blanc": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 blancs de poulet",
            "100g de tahini",
            "1 citron",
            "2 gousses d'ail",
            "Sel et poivre"
        ],
        "ingredient_liste": [
            "blancs-de-poulet",
            "tahini",
            "citron",
            "gousses-d'ail",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Mélanger le tahini, le jus de citron, l'ail écrasé, le sel et le poivre.",
            "Badigeonner les blancs de poulet avec le mélange.",
            "Laisser mariner pendant 30 minutes.",
            "Griller ou cuire au four jusqu'à ce que le poulet soit bien cuit."
        ],
        "cuisson": [
            "Griller ou cuire au four jusqu'à ce que le poulet soit bien cuit."
        ],
        "cooking_methods": {
            "best": "grill",
            "plancha": true,
            "friture": false,
            "barbecue": false
        },
        "mode_cuisson": ["grill", "plancha"],
        "accompagnement": "Servir avec du pain pita et une salade.",
        "shortStory": "Le Poulet au Tahini est un plat savoureux et crémeux, parfait pour ceux qui aiment les saveurs méditerranéennes. Le tahini, avec sa texture lisse et son goût de sésame, sublime le poulet, le rendant à la fois tendre et juteux.",
        "detailURL": "/fiche-recette/48/poulet-tahini/poulet-tahini.html",
        "time_prepare": "10mn",
        "time_maceration": "30mn",
        "time_cooking": "20mn",
        "crispys": false
    }

    ,
    {
        "id": 49,
        "categorie": "moyen-orient",
        "country": "Syrie",
        "title": "Poulet Shawarma",
        "subTitle": "Poulet mariné aux épices et rôti",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 cuisses de poulet",
            "2 cuillères à soupe de cumin",
            "2 cuillères à soupe de paprika",
            "1 yaourt nature",
            "1 citron"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "cumin",
            "paprika",
            "yaourt-nature",
            "citron"
        ],
        "preparation": [
            "Mélanger les épices avec le yaourt et le jus de citron.",
            "Mariner les cuisses de poulet pendant 1 heure.",
            "Griller les cuisses jusqu'à ce qu'elles soient dorées et bien cuites."
        ],
        "cuisson": [
            "Mélanger les épices avec le yaourt et le jus de citron. Mariner les cuisses de poulet pendant 1 heure. Griller les cuisses jusqu'à ce qu'elles soient dorées et bien cuites."
        ],
        "cooking_methods": {
            "best": "grill",
            "plancha": true,
            "friture": false,
            "barbecue": false
        },
        "mode_cuisson": ["grill", "plancha"],
        "accompagnement": "Servir avec du pain pita et une sauce au yaourt.",
        "detailURL": "/fiche-recette/49/poulet-shawarma/poulet-shawarma.html",
        "time_prepare": "15mn",
        "time_cooking": "30mn",
        "shortStory": "Le Poulet Shawarma est un plat emblématique du Moyen-Orient, connu pour sa marinade savoureuse et son goût juteux. Il est souvent servi dans du pain pita avec des légumes frais et une sauce au yaourt, parfait pour un repas convivial.",
        "time_maceration": "1h",
        "crispys": false
    }
    , {
        "id": 50,
        "categorie": "europe",
        "country": "Espagne",
        "title": "Poulet à la Plancha aux Épices",
        "subTitle": "Poulet épicé cuit à la plancha",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "blanc": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 blancs de poulet",
            "2 cuillères à café de paprika",
            "1 cuillère à café de cumin",
            "Sel et poivre",
            "2 cuillères à soupe d'huile d'olive"
        ],
        "ingredient_liste": [
            "blancs-de-poulet",
            "paprika",
            "cumin",
            "sel-et-poivre",
            "huile-d-olive"
        ],
        "preparation": [
            "Mélanger les épices avec l'huile d'olive.",
            "Badigeonner les blancs de poulet avec le mélange.",
            "Cuire à la plancha jusqu'à ce qu'ils soient dorés et cuits."
        ],
        "cuisson": [
            "Cuire à la plancha jusqu'à ce qu'ils soient dorés et cuits."
        ],
        "cooking_methods": {
            "best": "plancha",
            "grill": false,
            "friture": false,
            "barbecue": false
        },
        "mode_cuisson": ["plancha"],
        "accompagnement": "Servir avec une salsa de tomates.",
        "detailURL": "/fiche-recette/50/poulet-sspagne/poulet-espagne.html",
        "time_prepare": "10mn",
        "time_cooking": "15mn",
        "shortStory": "Ce poulet à la plancha aux épices est un plat savoureux d'Espagne, parfait pour les journées ensoleillées.",
        "time_maceration": "0mn",  // Peut être omis si non applicable
        "crispys": false
    }
    ,
    {
        "id": 51,
        "categorie": "moyen-orient",
        "country": "Liban",
        "title": "Poulet à la Coriandre",
        "subTitle": "Poulet mariné à la coriandre et aux épices",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "blanc": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 blancs de poulet",
            "1 bouquet de coriandre fraîche",
            "2 gousses d'ail",
            "2 cuillères à soupe d'huile d'olive",
            "Le jus d'un citron"
        ],
        "ingredient_liste": [
            "blancs-de-poulet",
            "coriandre-fraiche",
            "gousses-d'ail",
            "huile-d'olive",
            "jus-de-citron"
        ],
        "preparation": [
            "Mixer la coriandre, l'ail, l'huile d'olive et le jus de citron.",
            "Badigeonner les blancs de poulet avec la marinade et laisser mariner pendant 1 heure.",
            "Griller ou cuire à la plancha jusqu'à ce qu'ils soient bien cuits."
        ],
        "cuisson": [
            "Griller ou cuire à la plancha jusqu'à ce que le poulet soit bien cuit."
        ],
        "cooking_methods": {
            "best": "grill",
            "plancha": true,
            "friture": false,
            "barbecue": false
        },
        "mode_cuisson": ["grill", "plancha"],
        "accompagnement": "Servir avec un taboulé frais.",
        "shortStory": "Le Poulet à la Coriandre est une recette fraîche et parfumée, idéale pour les amateurs de saveurs herbacées. Marinée dans une sauce à base de coriandre, d'ail et de citron, cette préparation offre une explosion de saveurs lors de chaque bouchée.",
        "detailURL": "/fiche-recette/51/poulet-coriandre/poulet-coriandre.html",
        "time_prepare": "10mn",
        "time_maceration": "1h",
        "time_cooking": "20mn",
        "crispys": false
    }

    ,
    {
        "id": 52,
        "categorie": "moyen-orient",
        "country": "Tunisie",
        "title": "Poulet à la Harissa",
        "subTitle": "Poulet épicé à la harissa et aux légumes",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 cuisses de poulet",
            "2 cuillères à soupe de harissa",
            "1 poivron rouge",
            "1 oignon",
            "2 cuillères à soupe d'huile d'olive"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "harissa",
            "poivron-rouge",
            "oignon",
            "huile-d'olive"
        ],
        "preparation": [
            "Mélanger la harissa avec l'huile d'olive.",
            "Badigeonner les cuisses de poulet avec le mélange.",
            "Ajouter les légumes coupés autour du poulet.",
            "Cuire à la plancha ou au grill jusqu'à ce que le poulet soit bien cuit."
        ],
        "cuisson": [
            "Mélanger la harissa avec l'huile d'olive. Badigeonner les cuisses de poulet avec le mélange. Ajouter les légumes coupés autour du poulet. Cuire à la plancha ou au grill jusqu'à ce que le poulet soit bien cuit."
        ],
        "cooking_methods": {
            "best": "grill",
            "plancha": true,
            "friture": false,
            "barbecue": false
        },
        "mode_cuisson": ["grill", "plancha"],
        "accompagnement": "Servir avec du couscous.",
        "detailURL": "/fiche-recette/52/poulet-harissa/poulet-harissa.html",
        "time_prepare": "15mn",
        "time_cooking": "30mn",
        "shortStory": "Le Poulet à la Harissa est un plat emblématique de la cuisine tunisienne, connu pour ses saveurs épicées et sa simplicité. Ce plat réconfortant est parfait pour accompagner un bon couscous, offrant une explosion de saveurs en bouche.",
        "time_maceration": "0mn",
        "crispys": false
    }
    ,
    {
        "id": 53,
        "categorie": "moyen-orient",
        "country": "Maroc",
        "title": "Brochettes de Poulet aux Épices",
        "subTitle": "Délicieuses brochettes marinées aux épices",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "blanc": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "500g de blancs de poulet",
            "2 cuillères à soupe de cumin",
            "1 cuillère à soupe de paprika",
            "1 cuillère à café de cannelle",
            "2 cuillères à soupe d'huile d'olive"
        ],
        "ingredient_liste": [
            "blancs-de-poulet",
            "cumin",
            "paprika",
            "cannelle",
            "huile-d'olive"
        ],
        "preparation": [
            "Couper le poulet en cubes.",
            "Mélanger les épices et l'huile d'olive.",
            "Enrober les cubes de poulet avec le mélange et laisser mariner pendant 1 heure.",
            "Enfiler sur des brochettes et griller ou cuire à la plancha."
        ],
        "cuisson": [
            "Griller les brochettes sur un barbecue ou cuire à la plancha jusqu'à ce qu'elles soient bien dorées."
        ],
        "cooking_methods": {
            "best": "grill",
            "plancha": true,
            "friture": false,
            "barbecue": false
        },
        "mode_cuisson": ["grill", "plancha"],
        "accompagnement": "Servir avec une sauce au yaourt et du pain pita.",
        "detailURL": "/fiche-recette/53/brochettes-poulet-epices/brochettes-poulet-epices.html",
        "time_prepare": "20mn",
        "time_cooking": "15mn",
        "shortStory": "Ces brochettes de poulet aux épices sont un plat savoureux et parfumé, idéal pour un barbecue ou un repas convivial. La marinade aux épices rehausse le goût du poulet et lui donne une belle couleur dorée.",
        "time_maceration": "1h",
        "crispys": false
    }

    ,
    {
        "id": 54,
        "categorie": "moyen-orient",
        "country": "Egypte",
        "title": "Poulet au Citron et aux Épices",
        "subTitle": "Poulet tendre et juteux avec une touche de citron",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 cuisses de poulet",
            "1 citron",
            "2 cuillères à soupe de mélange d'épices (cumin, coriandre, paprika)",
            "2 cuillères à soupe d'huile d'olive",
            "Sel et poivre"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "citron",
            "mélange-d'épices",
            "huile-d'olive",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Presser le citron et mélanger le jus avec les épices et l'huile d'olive.",
            "Badigeonner les cuisses de poulet avec la marinade.",
            "Laisser mariner pendant au moins 1 heure.",
            "Griller ou cuire à la plancha jusqu'à ce que le poulet soit bien cuit."
        ],
        "cuisson": [
            "Presser le citron et mélanger le jus avec les épices et l'huile d'olive. Badigeonner les cuisses de poulet avec la marinade. Laisser mariner pendant au moins 1 heure. Griller ou cuire à la plancha jusqu'à ce que le poulet soit bien cuit."
        ],
        "cooking_methods": {
            "best": "grill",
            "plancha": true,
            "friture": false,
            "barbecue": false
        },
        "mode_cuisson": ["grill", "plancha"],
        "accompagnement": "Servir avec du riz basmati.",
        "detailURL": "/fiche-recette/54/poulet-citron-epices/poulet-citron-epices.html",
        "time_prepare": "10mn",
        "time_cooking": "25mn",
        "shortStory": "Le Poulet au Citron et aux Épices est un plat savoureux et rafraîchissant, mettant en valeur des saveurs vives grâce au citron et aux épices. Ce plat est parfait pour une soirée estivale et accompagne merveilleusement le riz basmati.",
        "time_maceration": "1h",
        "crispys": false
    }
    ,
    {
        "id": 56,
        "categorie": "afrique",
        "country": "Côte d'Ivoire",
        "title": "Poulet au Coco",
        "subTitle": "Poulet mijoté dans du lait de coco et des épices",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "blanc": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 blancs de poulet",
            "400 ml de lait de coco",
            "1 oignon",
            "1 poivron rouge",
            "2 cuillères à soupe d'huile",
            "Sel et poivre"
        ],
        "ingredient_liste": [
            "blancs-de-poulet",
            "lait-de-coco",
            "oignon",
            "poivron-rouge",
            "huile",
            "sel-et-poivre"
        ],
        "preparation": [
            "Faire revenir l'oignon et le poivron dans l'huile.",
            "Ajouter les blancs de poulet et faire dorer.",
            "Incorporer le lait de coco, le sel et le poivre, puis laisser mijoter pendant 30 minutes."
        ],
        "cuisson": [
            "Faire revenir l'oignon et le poivron dans l'huile. Ajouter les blancs de poulet et faire dorer. Incorporer le lait de coco, le sel et le poivre, puis laisser mijoter pendant 30 minutes."
        ],
        "cooking_methods": {
            "best": "cocotte",
            "plancha": false,
            "friture": false,
            "barbecue": false
        },
        "mode_cuisson": ["cocotte"],
        "accompagnement": "Servir avec du riz basmati.",
        "detailURL": "/fiche-recette/56/poulet-coco/poulet-coco.html",
        "time_prepare": "15mn",
        "time_cooking": "30mn",
        "shortStory": "Le poulet au coco est un plat savoureux et réconfortant, apprécié pour sa sauce riche et crémeuse, mettant en valeur les saveurs tropicales de la Côte d'Ivoire.",
        "time_maceration": "0mn",  // Peut être omis si non applicable
        "crispys": false
    },
    {
        "id": 58,
        "categorie": "amerique-sud",
        "country": "Argentine",
        "title": "Poulet au Chimichurri",
        "subTitle": "Poulet grillé avec une sauce chimichurri fraîche",
        "chicken_piece": ["blanc"],
        "piece": {
            "best": "blanc",
            "entier": false,
            "aile": false,
            "blanc": true,
            "pilon": false,
            "manchon": false,
            "cuisse": false
        },
        "ingredient": [
            "4 blancs de poulet",
            "1 bouquet de persil",
            "2 gousses d'ail",
            "1 cuillère à café de piment rouge",
            "2 cuillères à soupe de vinaigre",
            "4 cuillères à soupe d'huile d'olive"
        ],
        "ingredient_liste": [
            "blancs-de-poulet",
            "persil",
            "ail",
            "piment-rouge",
            "vinaigre",
            "huile-d-olive"
        ],
        "preparation": [
            "Mixer le persil, l'ail, le piment, le vinaigre et l'huile d'olive pour faire le chimichurri.",
            "Badigeonner les blancs de poulet avec la sauce et laisser mariner 30 minutes.",
            "Griller jusqu'à cuisson complète."
        ],
        "cuisson": [
            "Griller les blancs de poulet jusqu'à ce qu'ils soient bien cuits.",
            "Servir avec une salade fraîche."
        ],
        "cooking_methods": {
            "best": "grill",
            "plancha": true,
            "four": false,
            "friture": false
        },
        "mode_cuisson": ["grill", "plancha"],
        "accompagnement": "Servir avec une salade fraîche.",
        "shortStory": "Le poulet au chimichurri est un plat emblématique de la cuisine argentine. La marinade à base de persil frais, d'ail et de vinaigre apporte une explosion de saveurs qui rehausse le goût du poulet grillé.",
        "detailURL": "/fiche-recette/58/poulet-chimichurri/poulet-chimichurri.html",
        "time_prepare": "15mn",
        "time_maceration": "30mn",
        "time_cooking": "15mn",
        "crispys": false
    },
    {
        "id": 59,
        "categorie": "afrique",
        "country": "Sénégal",
        "title": "Mafé de Poulet",
        "subTitle": "Poulet mijoté dans une sauce crémeuse à l'arachide",
        "chicken_piece": ["entier", "cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": true,
            "aile": false,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "1 poulet entier découpé en morceaux (ou 4 cuisses de poulet)",
            "3 cuillères à soupe de pâte d'arachide (beurre de cacahuète non sucré)",
            "3 tomates fraîches ou 200g de tomates concassées",
            "2 oignons",
            "2 gousses d'ail",
            "1 carotte (facultatif)",
            "2 pommes de terre (facultatif)",
            "1 cube de bouillon de volaille ou de légumes",
            "2 cuillères à soupe d'huile d'arachide ou végétale",
            "1 morceau de piment (frais ou sec, selon goût)",
            "1 bouquet de persil frais",
            "Sel, poivre",
            "500 ml d'eau"
        ],
        "ingredient_liste": [
            "poulet",
            "pate-arachide",
            "tomates",
            "oignons",
            "ail",
            "carotte",
            "pommes-de-terre",
            "bouillon-volaille",
            "huile-arachide",
            "piment",
            "persil",
            "sel",
            "poivre",
            "eau"
        ],
        "preparation": [
            "Émincez les oignons et l'ail. Hachez les tomates si elles sont fraîches.",
            "Épluchez et coupez les pommes de terre et la carotte en gros morceaux (facultatif).",
            "Découpez et nettoyez le poulet en morceaux."
        ],
        "cuisson": [
            "Dans une cocotte, faites chauffer l'huile à feu moyen.",
            "Ajoutez les morceaux de poulet et faites-les dorer sur toutes les faces.",
            "Retirez le poulet et réservez. Dans la même cocotte, faites revenir les oignons et l'ail jusqu'à ce qu'ils soient dorés.",
            "Ajoutez les tomates hachées, laissez cuire quelques minutes, puis ajoutez la pâte d'arachide. Mélangez bien.",
            "Ajoutez le bouillon, le persil et le piment (ne pas retirer la queue).",
            "Versez l'eau et laissez mijoter 5 minutes.",
            "Remettez le poulet dans la cocotte, ajoutez les pommes de terre et la carotte.",
            "Laissez mijoter à feu doux pendant environ 45 minutes, jusqu'à ce que le poulet soit tendre et la sauce onctueuse."
        ],
        "cooking_methods": {
            "best": "mijoter",
            "plancha": false,
            "four": false,
            "friture": false,
            "grill": false
        },
        "mode_cuisson": ["cocotte"],
        "accompagnement": "Servez le Mafé de Poulet avec du riz blanc.",
        "shortStory": "Le Mafé de Poulet est un plat traditionnel sénégalais à base de poulet mijoté dans une sauce crémeuse à l'arachide. Cette recette, riche en saveurs, est un classique de la cuisine africaine qui met en valeur des ingrédients simples mais savoureux.",
        "detailURL": "/fiche-recette/101/mafe-poulet/mafe-poulet.html",
        "time_prepare": "15mn",
        "time_cooking": "45mn",
        "crispys": false
    },
    {
        "id": 60,
        "categorie": "oceanie",
        "country": "Polynésie française",
        "title": "Poulet Fa'a'apapa",
        "subTitle": "Poulet mariné dans une sauce au lait de coco et citron vert",
        "chicken_piece": ["entier", "blanc"],
        "piece": {
            "best": "entier",
            "entier": true,
            "aile": true,
            "blanc": true,
            "pilon": true,
            "manchon": true,
            "cuisse": true
        },
        "ingredient": [
            "1 poulet entier découpé en morceaux ou 4 blancs de poulet",
            "200 ml de lait de coco",
            "2 cuillères à soupe de jus de citron vert",
            "2 gousses d'ail émincées",
            "1 cuillère à soupe de sauce soja",
            "1 morceau de gingembre râpé (environ 1 cm)",
            "Sel, poivre"
        ],
        "ingredient_liste": [
            "poulet",
            "lait-coco",
            "citron-vert",
            "ail",
            "sauce-soja",
            "gingembre",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Mélangez le lait de coco, le jus de citron vert, l'ail, le gingembre et la sauce soja dans un grand bol.",
            "Ajoutez les morceaux de poulet et laissez mariner au réfrigérateur pendant au moins 2 heures."
        ],
        "cuisson": [
            "Faites griller le poulet mariné à feu moyen sur un barbecue ou au four jusqu'à ce qu'il soit bien doré et cuit à cœur."
        ],
        "cooking_methods": {
            "best": "grill",
            "plancha": true,
            "four": true,
            "friture": false,
            "grill": true
        },
        "mode_cuisson": ["barbecue", "four"],
        "accompagnement": "Servez avec du riz ou des légumes grillés.",
        "shortStory": "Le Fa'a'apapa est un plat traditionnel polynésien. Le poulet est mariné dans une sauce parfumée au lait de coco et citron vert, apportant des saveurs tropicales délicates.",
        "detailURL": "/fiche-recette/102/poulet-lait-coco-citron-vert-gingembre/Faaapapa.html",
        "time_prepare": "15mn",
        "time_cooking": "30mn",
        "time_maceration": "2h",
        "crispys": false
    },
    {
        "id": 61,
        "categorie": "oceanie",
        "country": "Nouvelle-Zélande",
        "title": "Poulet Hangi",
        "subTitle": "Poulet mariné aux herbes locales et cuit à la vapeur sous terre",
        "chicken_piece": ["entier", "pilon"],
        "piece": {
            "best": "pilon",
            "entier": true,
            "aile": true,
            "blanc": true,
            "pilon": true,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "4 pilons de poulet ou un poulet entier",
            "2 cuillères à soupe de miel",
            "1 cuillère à soupe de sauce soja",
            "1 cuillère à soupe d'huile d'olive",
            "1 gousse d'ail émincée",
            "1 cuillère à café de thym",
            "1 cuillère à café de romarin",
            "Sel, poivre"
        ],
        "ingredient_liste": [
            "poulet",
            "miel",
            "sauce-soja",
            "huile-olive",
            "ail",
            "thym",
            "romarin",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Mélangez le miel, la sauce soja, l'huile d'olive, l'ail, le thym et le romarin dans un bol.",
            "Ajoutez les morceaux de poulet et laissez mariner pendant 3 heures."
        ],
        "cuisson": [
            "Faites cuire le poulet mariné dans un four ou, pour une expérience authentique, dans un Hangi (four traditionnel souterrain)."
        ],
        "cooking_methods": {
            "best": "four",
            "plancha": false,
            "four": true,
            "friture": false,
            "grill": false
        },
        "mode_cuisson": ["four", "hangi"],
        "accompagnement": "Servez avec des légumes racines cuits à la vapeur ou grillés.",
        "shortStory": "Le Hangi est une méthode de cuisson traditionnelle maorie qui consiste à cuire les aliments dans un four souterrain. Cette recette apporte des saveurs riches et terreuses au poulet grâce à sa marinade aux herbes.",
        "time_prepare": "20mn",
        "time_cooking": "1h30",
        "time_maceration": "3h",
        "detailURL": "/fiche-recette/103/miel-sauce-soja-ail-romarin/Poulet-Hangi.html",
        "crispys": false
    },
    {
        "id": 62,
        "categorie": "oceanie",
        "country": "Fidji",
        "title": "Poulet à l'Ananas",
        "subTitle": "Poulet mariné dans une sauce sucrée-salée à l'ananas",
        "chicken_piece": ["entier", "manchon"],
        "piece": {
            "best": "manchon",
            "entier": true,
            "aile": true,
            "blanc": true,
            "pilon": true,
            "manchon": true,
            "cuisse": true
        },
        "ingredient": [
            "6 manchons de poulet",
            "150 g d'ananas frais ou en conserve",
            "2 cuillères à soupe de sauce soja",
            "1 cuillère à soupe de sucre brun",
            "1 gousse d'ail émincée",
            "1 cuillère à soupe de vinaigre de riz",
            "Sel, poivre"
        ],
        "ingredient_liste": [
            "poulet",
            "ananas",
            "sauce-soja",
            "sucre-brun",
            "ail",
            "vinaigre-riz",
            "sel",
            "poivre"
        ],
        "preparation": [
            "Mélangez l'ananas, la sauce soja, le sucre brun, l'ail et le vinaigre dans un bol.",
            "Ajoutez les manchons de poulet et laissez mariner pendant au moins 2 heures."
        ],
        "cuisson": [
            "Faites griller ou cuire au four jusqu'à ce que le poulet soit doré et caramélisé."
        ],
        "cooking_methods": {
            "best": "grill",
            "plancha": true,
            "four": true,
            "friture": false,
            "grill": true
        },
        "mode_cuisson": ["grill", "four"],
        "accompagnement": "Servez avec du riz basmati ou des légumes sautés.",
        "shortStory": "Cette recette fidjienne combine des saveurs sucrées et salées avec une marinade à l'ananas, créant une explosion tropicale dans chaque bouchée.",
        "time_prepare": "15mn",
        "time_cooking": "40mn",
        "crispys": false,
        "time_maceration": "3h",
        "detailURL": "/fiche-recette/104/ananas-sucre-brun-sauce-soja-vinaigre-riz/Poulet-Ananas.html",
    },
    {
        "id": 63,
        "categorie": "europe",
        "country": "Portugal",
        "title": "Poulet Mariné piri-piri ",
        "subTitle": "Marinade épicée et parfumée aux saveurs portugaises et africaines du Mozambique et de l'Angola",
        "chicken_piece": ["cuisse", "pilon"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "pilon": true,
            "manchon": false,
            "cuisse": true,
            "poitrine": false
        },
        "ingredient": [
            "6 pilons ou cuisses de poulet",
            "4 cuillères à soupe d'huile d'olive",
            "2 cuillères à soupe de jus de citron frais",
            "3 gousses d'ail écrasées",
            "1 cuillère à soupe de paprika fumé",
            "1 cuillère à café de piment d'Espelette ou de pili-pili",
            "2 cuillères à soupe de coriandre fraîche hachée",
            "1 cuillère à café de gingembre frais râpé",
            "1 cuillère à soupe de sauce soja",
            "1/2 cuillère à café de sel",
            "1/2 cuillère à café de poivre noir"
        ],
        "ingredient_liste": [
            "pilon-de-poulet",
            "huile-d'olive",
            "jus-de-citron",
            "ail",
            "paprika-fume",
            "piment-d'espelette",
            "coriandre-fraiche",
            "gingembre",
            "sauce-soja",
            "sel",
            "poivre-noir"
        ],
        "preparation": [
            "Dans un bol, mélanger l'huile d'olive, le jus de citron, l'ail écrasé, le paprika fumé, le piment d'Espelette, la coriandre hachée, le gingembre, la sauce soja, le sel et le poivre.",
            "Ajouter les morceaux de poulet et bien les enrober de marinade.",
            "Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement toute une nuit pour une meilleure absorption des saveurs."
        ],
        "cuisson": [
            "Préchauffer le grill ou le four à feu moyen-élevé.",
            "Retirer le poulet de la marinade et jeter l'excès de marinade.",
            "Griller ou rôtir le poulet pendant 20 à 25 minutes, en le retournant à mi-cuisson, jusqu'à ce qu'il soit bien doré et cuit à cœur.",
            "Laisser reposer quelques minutes avant de servir pour permettre aux jus de se répartir."
        ],
        "cooking_methods": {
            "best": "grill",
            "plancha": true,
            "four": true,
            "grill": true
        },
        "mode_cuisson": ["grill", "four", "plancha"],
        "accompagnement": "Ce poulet est délicieux accompagné de riz safrané, de légumes grillés ou de pommes de terre sautées.",
        "shortStory": "Ce plat combine les saveurs fumées portugaises avec le piquant de l'Afrique. Parfait pour les barbecues ou un repas convivial, cette marinade ajoute une profondeur unique au poulet.",
        "detailURL": "/fiche-recette/63/poulet-marine-afro-portugais/poulet-marine-sauce-piri-piri.html",
        "time_prepare": "15mn",
        "time_maceration": "2h",
        "time_cooking": "25mn",
        "crispys": false
    },
    {
        "id": 64,
        "categorie": "afrique",
        "country": "Mozambique et Angola",
        "title": "Poulet Mariné piri-piri ",
        "subTitle": "Marinade épicée et parfumée aux saveurs portugaises et africaines du Mozambique et de l'Angola",
        "chicken_piece": ["cuisse", "pilon"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "pilon": true,
            "manchon": false,
            "cuisse": true,
            "poitrine": false
        },
        "ingredient": [
            "6 pilons ou cuisses de poulet",
            "4 cuillères à soupe d'huile d'olive",
            "2 cuillères à soupe de jus de citron frais",
            "3 gousses d'ail écrasées",
            "1 cuillère à soupe de paprika fumé",
            "1 cuillère à café de piment d'Espelette ou de pili-pili",
            "2 cuillères à soupe de coriandre fraîche hachée",
            "1 cuillère à café de gingembre frais râpé",
            "1 cuillère à soupe de sauce soja",
            "1/2 cuillère à café de sel",
            "1/2 cuillère à café de poivre noir"
        ],
        "ingredient_liste": [
            "pilon-de-poulet",
            "huile-d'olive",
            "jus-de-citron",
            "ail",
            "paprika-fume",
            "piment-d'espelette",
            "coriandre-fraiche",
            "gingembre",
            "sauce-soja",
            "sel",
            "poivre-noir"
        ],
        "preparation": [
            "Dans un bol, mélanger l'huile d'olive, le jus de citron, l'ail écrasé, le paprika fumé, le piment d'Espelette, la coriandre hachée, le gingembre, la sauce soja, le sel et le poivre.",
            "Ajouter les morceaux de poulet et bien les enrober de marinade.",
            "Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement toute une nuit pour une meilleure absorption des saveurs."
        ],
        "cuisson": [
            "Préchauffer le grill ou le four à feu moyen-élevé.",
            "Retirer le poulet de la marinade et jeter l'excès de marinade.",
            "Griller ou rôtir le poulet pendant 20 à 25 minutes, en le retournant à mi-cuisson, jusqu'à ce qu'il soit bien doré et cuit à cœur.",
            "Laisser reposer quelques minutes avant de servir pour permettre aux jus de se répartir."
        ],
        "cooking_methods": {
            "best": "grill",
            "plancha": true,
            "four": true,
            "grill": true
        },
        "mode_cuisson": ["grill", "four", "plancha"],
        "accompagnement": "Ce poulet est délicieux accompagné de riz safrané, de légumes grillés ou de pommes de terre sautées.",
        "shortStory": "Ce plat combine les saveurs fumées portugaises avec le piquant de l'Afrique. Parfait pour les barbecues ou un repas convivial, cette marinade ajoute une profondeur unique au poulet.",
        "detailURL": "/fiche-recette/64/poulet-marine-piri-piri/poulet-piri-piri.html",
        "time_prepare": "15mn",
        "time_maceration": "2h",
        "time_cooking": "25mn",
        "crispys": false
    },
    {
        "id": 65,
        "categorie": "asiatique",
        "country": "Corée du Sud",
        "title": "Dak Galbi",
        "subTitle": "Poulet épicé à la coréenne avec légumes",
        "chicken_piece": ["cuisse"],
        "piece": {
            "best": "cuisse",
            "entier": false,
            "aile": false,
            "blanc": false,
            "pilon": false,
            "manchon": false,
            "cuisse": true
        },
        "ingredient": [
            "500g de cuisses de poulet désossées et coupées en morceaux",
            "1/4 tasse de pâte de piment coréenne (gochujang)",
            "2 cuillères à soupe de sauce soja",
            "2 cuillères à soupe de sucre",
            "1 cuillère à soupe de vinaigre de riz",
            "1 cuillère à soupe d'huile de sésame",
            "2 gousses d'ail, émincées",
            "1 cuillère à café de gingembre râpé",
            "1 oignon, émincé",
            "1/2 chou, coupé en morceaux",
            "1 patate douce, pelée et tranchée finement",
            "2 oignons verts, émincés",
            "Graines de sésame pour garnir"
        ],
        "ingredient_liste": [
            "cuisses-de-poulet",
            "gochujang",
            "sauce-soja",
            "sucre",
            "vinaigre-de-riz",
            "huile-de-sesame",
            "ail",
            "gingembre"
        ],
        "preparation": [
            "Dans un grand bol, mélanger la pâte de piment, la sauce soja, le sucre, le vinaigre de riz, l'huile de sésame, l'ail émincé et le gingembre râpé pour préparer la marinade.",
            "Ajouter les morceaux de poulet dans le bol et bien les enrober de marinade.",
            "Couvrir et laisser mariner au réfrigérateur pendant au moins 30 minutes, ou jusqu'à 2 heures pour plus de saveur."
        ],
        "cuisson": [
            "Dans une grande poêle ou un wok, chauffer un peu d'huile végétale à feu moyen-élevé.",
            "Ajouter les morceaux de poulet marinés et faire sauter jusqu'à ce qu'ils commencent à dorer.",
            "Ajouter l'oignon, le chou, et la patate douce et continuer à faire sauter jusqu'à ce que les légumes soient tendres et le poulet bien cuit, environ 10-15 minutes.",
            "Garnir d'oignons verts émincés et de graines de sésame avant de servir."
        ],
        "cooking_methods": {
            "best": "poêle",
            "plancha": false,
            "four": false,
            "barbecue": false
        },
        "mode_cuisson": ["poêle", "wok"],
        "accompagnement": "Le Dak Galbi est souvent servi avec du riz à la vapeur et des feuilles de laitue pour envelopper le poulet et les légumes. Vous pouvez aussi l'accompagner de kimchi pour plus de saveur.",
        "shortStory": "Le Dak Galbi est un plat populaire en Corée du Sud, surtout apprécié dans les régions montagneuses. Ce plat de poulet mariné épicé est sauté avec des légumes, apportant un mélange unique de saveurs relevées et légèrement sucrées, typiques de la cuisine coréenne.",
        "detailURL": "/fiche-recette/23/gochujang-patate-douce-oignons-verts/dak-galbi.html",
        "time_prepare": "20mn",
        "time_maceration": "30mn",
        "time_cooking": "15mn",
        "crispys": false
    }



];
export default GridSet;
