import React from 'react';
import {Container} from "react-bootstrap";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Mettre à jour l'état pour afficher l'interface utilisateur de secours
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Vous pouvez également enregistrer l'erreur dans un service de rapport d'erreurs
        console.error("Erreur capturée par Error Boundary: ", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <Container style={{textAlign:"center",marginTop:"30px",color:"#515151"}}  >
                <h1 style={{fontSize:"4em"}}>Oulala ....  une erreur est survenue :(</h1>
                <h5 style={{textAlign:"center",}}>Continuez votre visite pour trouver votre marinade de poulet ;)</h5>
                <br /><br /><hr />
            </Container>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;