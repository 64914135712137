import React, { Component } from "react";
import {Col, Container, Row, Card, Modal, Button} from "react-bootstrap";
import Jumbotron from "react-bootstrap/Jumbotron";
import { Helmet } from "react-helmet";
import './About.css'; // Assurez-vous d'avoir les nouveaux styles
import { FaGlobe, FaFire, FaDrumstickBite, FaStar, FaSearch, FaArrowDown} from 'react-icons/fa'; // Ajout des icônes Apple, Android et Windows
import MainLogo from "../Medias/poulet-sans-fond-lettrage-b.png";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

class About extends Component {
    constructor(props) {
        super(props);
        // Vérifier le localStorage pour voir si l'utilisateur a choisi "Je ne suis pas intéressé"
        const isNotInterested = localStorage.getItem('notInterested') === 'false';
        // Initialiser showInstallSection à true si l'application n'est pas installée et que l'utilisateur n'a pas choisi de ne pas être intéressé
        this.state = {
            showInstallSection: !isNotInterested && !window.matchMedia('(display-mode: standalone)').matches,
            showModal: false, // Pour contrôler l'état de la modal
            videoLoaded: false
        };
    }
    // Fonction pour ouvrir la modal
    handleShow = () => {
        this.setState({ showModal: true, videoLoaded: true }); // Charger la vidéo au moment de l'ouverture
    };

    // Fonction pour fermer la modal
    handleClose = () => {
        this.setState({ showModal: false, videoLoaded: false }); // Décharger la vidéo si la modal est fermée
    };

    render() {
        return (
            <Jumbotron
                style={{
                    backgroundPosition: 'top',
                    backgroundSize: 'cover',
                    backgroundRepeat: "no-repeat",
                    background: "#fff",
                    marginTop: "-0px",
                    paddingTop: "0px",
                }}
                fluid
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Marinade de poulet - Un catalogue de recettes de marinades du monde inratables</title>
                    <meta name="description" content="50 idées de recette pour faire une marinade de poulet facile à la maison" />
                </Helmet>

                <Container fluid style={{ backgroundColor: "#fff", marginTop: "20px" }}>
                    <Row className="section-1">
                        <Col md={{ span: 8, offset: 2 }} className="text-left">
                            <h1 className="display-4 mb-4" style={{ fontSize: "1.5em", fontWeight: "bold" }}>
                                Un catalogue pour trouver votre recette de marinade pour poulet ?
                            </h1>
                            <p className="">
                                Bienvenue sur marinade-poulet.com.<br />
                                Marinade Poulet est né de la difficulté à trouver <b>un site qui centralise exclusivement des recettes de marinades pour le poulet</b>.
                                <br />Nous avons donc créé ce <b>catalogue unique</b> pour rassembler les meilleures recettes de marinades du monde entier, adaptées à toutes les méthodes de cuisson et tous les morceaux de poulet.
                            </p>
                            <h2 style={{ fontSize: "1.5em", fontWeight: "bold" }}>Le poulet à travers le monde</h2>
                            <p>
                                Le poulet est l'une des protéines les plus consommées au monde, apprécié pour sa polyvalence et sa capacité à s'adapter à une multitude de cuisines. Des marinades épicées du Mexique aux préparations douces de l’Inde, en passant par les recettes grillées de l'Afrique et les plats mijotés d'Europe, <b>chaque culture apporte sa propre touche unique à ce délicieux ingrédient</b>.
                                <br />Sur marinade-poulet.com, nous vous invitons à <b>explorer ces différentes traditions culinaires</b> et à découvrir comment le poulet peut être préparé et savouré de manière créative, tout en célébrant les richesses gastronomiques du monde.
                            </p>
                            <h2 style={{ fontSize: "1.5em", fontWeight: "bold" }}>Des recettes de marinades faciles, saines et faites maison</h2>
                            <p>Notre ambition est de rendre facile la découverte de nouvelles marinades, en mettant en avant <b>des recettes faites maison et saines</b>, afin que vous puissiez cuisiner avec des ingrédients simples et naturels.</p>
                            <hr />
                            <p className="lead text-center" style={{ fontSize: '1em' }}>
                                <strong style={{ color: "#2E8B57" }}>Comment transformez votre poulet avec notre compilation de recettes de marinade !?</strong>
                            </p>
                            <p className="lead text-center">Voici comment utiliser l'application avec ses différentes manières de rechercher et parcourir notre catalogue de recette.<br />
                                <br /><FaArrowDown size={20} /><br /><br />
                                <Button
                                    variant="primary"
                                    onClick={this.handleShow}>
                                    Voir la vidéo tutoriel
                                </Button>
                            </p><hr />
                            {/* Modal contenant la vidéo */}
                            <Modal show={this.state.showModal} onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Comment utiliser marinade-poulet.com</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {this.state.videoLoaded && (
                                        <video width="100%" height="auto" controls>
                                            <source src="/videos/reel.mp4" type="video/mp4" />
                                            Votre navigateur ne supporte pas les vidéos HTML5.
                                        </video>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Fermer
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Row>
                </Container>

                <Container style={{ backgroundColor: "#fff", marginTop: "-100px" }}>
                    <Row className="mt-5">
                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#FFDB58" }}>
                                <Card.Body>
                                    <FaFire size={50} className="mb-3 text-dark" />
                                    <Card.Title className="text-dark">Recherche par mode de cuisson</Card.Title>
                                    <Card.Text>
                                        Que vous préfériez le barbecue, le grill ou la cuisson au four, trouvez la marinade parfaite pour chaque méthode.
                                    </Card.Text>
                                    <Link
                                        to="/poulet-mode-cuisson/marinades.html"
                                        title="Voir le catalogue de recettes pour tout mode de cuisson"
                                        className="btn btn-home"
                                        style={{ textDecoration: "none", padding: "10px 20px" }}
                                        aria-label='Voir le catalogue de recettes pour tout mode de cuisson'
                                    >
                                        Commencer à mariner
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#40E0D0" }}>
                                <Card.Body>
                                    <FaGlobe size={50} className="mb-3 text-dark" />
                                    <Card.Title className="text-dark">Exploration par région ou continent</Card.Title>
                                    <Card.Text>
                                        Embarquez pour un voyage culinaire à travers l’Asie, l’Afrique, l’Europe ou les Amériques avec des saveurs authentiques.
                                        Votre poulet à besoin d'un passeport !
                                    </Card.Text>
                                    <Link
                                        to="/poulet-dans-le-monde/marinades.html"
                                        title="Voir le catalogue de recettes du monde"
                                        className="btn btn-home"
                                        style={{ textDecoration: "none", padding: "10px 20px" }}
                                        aria-label='Voir le catalogue de recettes du monde'
                                    >
                                        Commencer à mariner
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#FF69B4" }}>
                                <Card.Body>
                                    <FaDrumstickBite size={50} className="mb-3 text-dark" />
                                    <Card.Title className="text-dark">Sélection par morceau de poulet</Card.Title>
                                    <Card.Text>
                                        Découvrez des marinades spécifiquement conçues pour les ailes, cuisses, filets ou poulets entiers.
                                    </Card.Text>
                                    <Link
                                        to="/poulet-morceau-choisi/marinades.html"
                                        title="Voir le catalogue des marinades par morceau de poulet"
                                        className="btn btn-home"
                                        style={{ textDecoration: "none", padding: "10px 20px" }}
                                        aria-label='Voir le catalogue des marinades par morceau de poulet'
                                    >
                                        Commencer à mariner
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#FFA500" }}>
                                <Card.Body>
                                    <FaStar size={50} className="mb-3 text-dark" />
                                    <Card.Title className="text-dark">Section spéciale poulet croustillant</Card.Title>
                                    <Card.Text>
                                        Vous adorez le poulet croustillant ? Retrouvez nos recettes et astuces pour réussir un poulet croustillant, doré et savoureux à chaque fois.
                                    </Card.Text>
                                    <Link
                                        to="/croustillant/crispys.html"
                                        title="Voir le catalogue des croustillants"
                                        className="btn btn-home"
                                        style={{ textDecoration: "none", padding: "10px 20px" }}
                                        aria-label='Voir le catalogue des croustillants'
                                    >
                                        Commencer à mariner
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#28A745" }}>
                                <Card.Body>
                                    <FaSearch size={50} className="mb-3 text-dark" />
                                    <Card.Title className="text-dark">Recherche par ingrédient ou mot-clé</Card.Title>
                                    <Card.Text>
                                        Vous avez un ingrédient à disposition ou une envie précise ? Trouvez la marinade qui correspond à votre inspiration du moment.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Logo Card avec animation */}
                        <Col md={4}>
                            <Card className="custom-card logo-card text-center" style={{backgroundColor:"#000", color:"#fff"}}>
                                <Card.Body >
                                    <img src={MainLogo} width="640" height="360" alt="catalogue de recettes de marinade de poulet" className="logo-img" />
                                    <p style={{backgroundColor:"#000", color:"#fff"}}>Notre site est également une Progressive Web App (PWA), ce qui signifie que vous pouvez profiter d’une expérience fluide et rapide, que vous soyez sur ordinateur ou mobile.
                                        <br/>Accédez facilement à nos recettes où que vous soyez, <b>même sans connexion internet</b>.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="text-center mt-5">
                        <Col>
                            <p>
                                <a
                                    href="https://www.instagram.com/marinade_poulet/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Suivez marinade de poulet sur Instagram"
                                >
                                    <FontAwesomeIcon icon={faInstagram} style={{ fontSize: '25px', color: '#E4405F' }} />
                                    <span  style={{ fontSize: '25px', color: '#E4405F' }}>&nbsp; Follow us</span>
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        );
    }
}

export default About;
