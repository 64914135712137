import React, { useState, useEffect } from 'react';
import './CookieConsentGTM.css';
import { updateConsentSettings } from './gtm';

const CookieConsentBanner = ({ onSaveConsent }) => {
    const [consentSettings, setConsentSettings] = useState({
        necessary: true,
        analytics: true,
        marketing: true,
    });
    const [preferencesExist, setPreferencesExist] = useState(false);
    const [showFullBanner, setShowFullBanner] = useState(false);
    const isBot = /bot|crawl|spider|Lighthouse|PTST|Headless/i.test(navigator.userAgent);

    useEffect(() => {
        if (isBot) return;

        const storedConsentSettings = localStorage.getItem('cookieConsentSettings');
        if (storedConsentSettings) {
            setConsentSettings(JSON.parse(storedConsentSettings));
            setPreferencesExist(true);
            updateConsentSettings(JSON.parse(storedConsentSettings));
        }
    }, [isBot]);

    const handleToggle = (category) => {
        setConsentSettings((prevState) => ({
            ...prevState,
            [category]: !prevState[category],
        }));
    };

    const handleSave = () => {
        onSaveConsent(consentSettings);
        localStorage.setItem('cookieConsentSettings', JSON.stringify(consentSettings));
        updateConsentSettings(consentSettings);
        setPreferencesExist(true);
    };

    const handleAcceptDefault = () => {
        handleSave();
    };

    const handleShowSettings = () => {
        setShowFullBanner(true);
    };

    if (isBot) return null;

    return (
        <div className="cookie-consent-overlay">
            <div className="cookie-consent-banner">
                {!preferencesExist && !showFullBanner ? (
                    <>
                        <p>
                            Nous utilisons des cookies pour améliorer votre expérience.
                            En continuant, vous acceptez la configuration par défaut.
                        </p>
                        <button className="cookie-btn" onClick={handleAcceptDefault}>
                            Accepter la configuration par défaut
                        </button>
                        <button className="cookie-btn-secondary" onClick={handleShowSettings}>
                            Gérer mes paramètres
                        </button>
                    </>
                ) : (
                    <>
                        <h4>Préférences de confidentialité</h4>
                        <p>
                            Nous utilisons des cookies pour améliorer votre expérience de navigation.
                            Vous pouvez personnaliser vos préférences ci-dessous en activant ou désactivant certains types de cookies.
                        </p>
                        <ul>
                            <li>
                                <label>
                                    <input type="checkbox" checked={consentSettings.necessary} disabled />
                                    <strong>Cookies nécessaires</strong> : Ces cookies sont essentiels au fonctionnement du site et ne peuvent pas être désactivés.
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={consentSettings.analytics}
                                        onChange={() => handleToggle('analytics')}
                                    />
                                    <strong>Cookies analytiques</strong> : Ces cookies collectent des données anonymes pour améliorer les performances du site.
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={consentSettings.marketing}
                                        onChange={() => handleToggle('marketing')}
                                    />
                                    <strong>Cookies marketing</strong> : Ces cookies aident à personnaliser la publicité pour qu’elle soit plus pertinente.
                                </label>
                            </li>
                        </ul>
                        <button className="cookie-btn" onClick={handleSave}>
                            Enregistrer mes préférences
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default CookieConsentBanner;
