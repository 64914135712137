// gtm.js
export const updateConsentSettings = (consentSettings) => {
    const gtmId = 'GTM-MTZWJVQM'; // Remplacez par votre propre identifiant GTM

    // Initialisation de la dataLayer si elle n'existe pas encore
    if (!window.dataLayer) {
        window.dataLayer = [];
    }

    // Pousser les paramètres de consentement dans la dataLayer
    window.dataLayer.push({
        event: 'consent_update',
        consent: {
            ad_storage: consentSettings.marketing ? 'granted' : 'denied',
            analytics_storage: consentSettings.analytics ? 'granted' : 'denied',
        },
    });

    // Débogage
    console.log('Consent settings pushed to dataLayer:', {
        event: 'consent_update',
        consent: {
            ad_storage: consentSettings.marketing ? 'granted' : 'denied',
            analytics_storage: consentSettings.analytics ? 'granted' : 'denied',
        },
    });

    // Charger GTM uniquement si le consentement est accordé pour l'analytics ou le marketing et si le script n'est pas déjà présent
    if ((consentSettings.analytics || consentSettings.marketing) && !document.getElementById('gtm-script')) {
        const script = document.createElement('script');
        script.id = 'gtm-script';
        script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
        script.async = true;
        script.onerror = () => {
            console.error('Erreur lors du chargement de Google Tag Manager');
        };
        document.body.appendChild(script);
    }
};



