import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaHistory} from "react-icons/fa";  // Importation de l'icône d'historique
import Share from "./Share";
import Bookmarks from "./Bookmarks";
import './Share.css';

const RecentRecipesSelectHistory = () => {
    const [recentRecipes, setRecentRecipes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedRecipeTitle, setSelectedRecipeTitle] = useState("Vues récentes"); // Valeur par défaut affichée

    // Charger les recettes récentes depuis le localStorage au montage du composant
    useEffect(() => {
        const storedRecipes = JSON.parse(localStorage.getItem('recentRecipes')) || [];
        setRecentRecipes(storedRecipes);
    }, []);

    // Ouvrir et fermer la modal
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    // Gérer la sélection d'une recette et réinitialiser la sélection
    const handleRecipeClick = (recipeTitle) => {
        setSelectedRecipeTitle("Dernières vues"); // Réinitialise le titre après sélection
        handleClose(); // Ferme la modal
    };

    return (
        <Container>
            <Row style={{  borderRadius:'2px' ,
                boxShadow:'0px 0px 2px 0px ',
                marginBottom: "-45px" ,
                backgroundColor:'#fff',
                paddingTop:'10px',
                marginTop:'-10px'}}>
                <Col xs={12} xd={12} style={{textAlign:'center', marginBottom:'-10px', }}>
                <Share />
                <Bookmarks />

                {/* Affiche l'icône pour ouvrir la modal seulement s'il y a des recettes récentes */}
                {recentRecipes.length > 0 && (

                        <button onClick={handleShow} title="Partager" style={{marginTop:"-8px"}}>
                            <FaHistory style={{fontSize:"20px",color:"#757575"}} />
                            <br/>
                            <sup style={{fontSize:"10px", color:"#757575"}}>{selectedRecipeTitle}</sup>
                        </button>

                )}
                </Col>
            </Row>

            {/* Modal pour afficher les recettes récentes */}
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Vues récentes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {recentRecipes.map((recipe, index) => (
                        <Link
                            key={index}
                            to={recipe.detailURL}
                            style={{ display: "block", padding: "10px 0", color: "#333", textDecoration: "none" }}
                            onClick={() => handleRecipeClick(recipe.title)}
                        >
                            {recipe.title}
                        </Link>
                    ))}
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default RecentRecipesSelectHistory;
